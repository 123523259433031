import { EventType } from '../types';
import { SubEventGroup } from './SubEventGroup';
import * as S from './SubEvents.styled';

interface SubEventsProps {
  events: EventType[];
}

export const SubEvents = ({ events }: SubEventsProps) => {
  return (
    <S.SubEvents>
      {Object.values(events)
        ?.sort((a: EventType, b: EventType) =>
          a?.timestamp < b?.timestamp ? -1 : 0,
        )
        ?.map((eventGroup: EventType) => {
          return (
            <SubEventGroup key={eventGroup?.uuid} eventGroup={eventGroup} />
          );
        })}
    </S.SubEvents>
  );
};
