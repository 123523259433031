import * as S from './EventParticipants.styled';

import EventParticipant from '../EventParticipant/EventParticipant';
import {
  MatchesType,
  NeoLineupType,
  NeoParticipantType,
} from '@ntb-sport/types';

import { useState } from 'react';

import AbbreviationInfo from '../../sportedit/AbbreviationInfo/AbbreviationInfo';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';

interface EventParticipantsProps {
  eventParticipants: NeoParticipantType[];
  isLoading?: boolean;
  matches: MatchesType;
  enableToggle?: boolean;
  theme?: any;
  highlightedCountry?: string;
  sportGroup?: string;
  competitionId?: string;
}

export const EventParticipants = ({
  eventParticipants,
  isLoading,
  matches,
  enableToggle,
  highlightedCountry = 'country-norway',
  sportGroup,
  competitionId,
}: EventParticipantsProps) => {
  const [expandedParticipants, setExpandedParticipants] = useState<string[]>(
    [],
  );

  const onToggleParticipant = (uuid: string) => {
    if (expandedParticipants.includes(uuid)) {
      setExpandedParticipants(expandedParticipants.filter((id) => id !== uuid));
    } else {
      setExpandedParticipants([...expandedParticipants, uuid]);
    }
  };

  const groupByPair = eventParticipants?.reduce(
    (eventParticipants: any, eventParticipant: NeoParticipantType) => {
      if (
        Object.prototype.hasOwnProperty.call(eventParticipant, 'pair') &&
        !eventParticipants[eventParticipant.pair]
      ) {
        eventParticipants[eventParticipant.pair] = [];
      }

      if (Object.prototype.hasOwnProperty.call(eventParticipant, 'pair')) {
        eventParticipants[eventParticipant.pair].push(eventParticipant);
      }
      return eventParticipants;
    },
    {},
  );

  return (
    <div
      data-component-id={COMPONENT_IDS.EVENT_PARTICIPANTS}
      data-competition-id={competitionId}
    >
      <S.Container>
        {isLoading ? (
          <S.StyledEventParticipants>
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
            <Skeleton height="45px" mb={1} />
          </S.StyledEventParticipants>
        ) : (!eventParticipants || !eventParticipants?.length) && !isLoading ? (
          <S.NoResults>Startlisten er ikke klar.</S.NoResults>
        ) : groupByPair && Object.keys(groupByPair)?.length ? (
          <>
            <S.EventParticipantsHeader>
              <S.AbbreviationInfoContainer>
                <AbbreviationInfo sportGroup={sportGroup} />
              </S.AbbreviationInfoContainer>
            </S.EventParticipantsHeader>
            {Object.values(groupByPair)?.map(
              (eventParticipants: any, index: number) => (
                <S.Pair key={index}>
                  <S.PairName>Par {index + 1}</S.PairName>
                  <S.StyledEventParticipants>
                    {eventParticipants
                      ?.sort((a: NeoParticipantType, b: NeoParticipantType) => {
                        if (a?.startingOrder || b?.startingOrder) {
                          return a?.startingOrder < b?.startingOrder ? -1 : 0;
                        } else {
                          return a?.bibNumber < b?.bibNumber ? -1 : 0;
                        }
                      })
                      ?.map((eventParticipant: NeoParticipantType) => (
                        <Participant
                          key={eventParticipant?.uuid}
                          eventParticipant={eventParticipant}
                          highlightedCountry={highlightedCountry}
                          matches={matches}
                          enableToggle={enableToggle}
                          onToggleParticipant={onToggleParticipant}
                          expandedParticipants={expandedParticipants}
                        />
                      ))}
                  </S.StyledEventParticipants>
                </S.Pair>
              ),
            )}
          </>
        ) : (
          <>
            <S.EventParticipantsHeader>
              <S.AbbreviationInfoContainer>
                <AbbreviationInfo sportGroup={sportGroup} />
              </S.AbbreviationInfoContainer>
            </S.EventParticipantsHeader>
            <S.StyledEventParticipants data-element-id={ELEMENT_IDS.LIST}>
              {eventParticipants
                ?.sort((a: NeoParticipantType, b: NeoParticipantType) => {
                  if (a?.startingOrder || b?.startingOrder) {
                    return a?.startingOrder < b?.startingOrder ? -1 : 0;
                  } else {
                    return a?.bibNumber < b?.bibNumber ? -1 : 0;
                  }
                })
                ?.map((eventParticipant: NeoParticipantType) => (
                  <Participant
                    key={eventParticipant?.uuid}
                    eventParticipant={eventParticipant}
                    highlightedCountry={highlightedCountry}
                    matches={matches}
                    enableToggle={enableToggle}
                    onToggleParticipant={onToggleParticipant}
                    expandedParticipants={expandedParticipants}
                  />
                ))}
            </S.StyledEventParticipants>
          </>
        )}
      </S.Container>
    </div>
  );
};

interface ParticipantProps {
  eventParticipant: NeoParticipantType;
  matches: MatchesType;
  highlightedCountry: string;
  enableToggle: boolean | undefined;
  onToggleParticipant: any;
  expandedParticipants: any;
}

const Participant = ({
  eventParticipant,
  matches,
  highlightedCountry,
  enableToggle,
  onToggleParticipant,
  expandedParticipants,
}: ParticipantProps) => {
  const highlight =
    eventParticipant?.team?.country?.uuid === highlightedCountry ||
    eventParticipant?.person?.countries?.[0]?.uuid === highlightedCountry;
  return (
    <S.EventParticipant
      key={eventParticipant?.uuid}
      $matches={matches}
      $isOpen={expandedParticipants?.includes(eventParticipant?.uuid)}
      $enableToggle={enableToggle}
      onClick={() =>
        enableToggle && onToggleParticipant(eventParticipant?.uuid)
      }
      data-element-id={ELEMENT_IDS.LIST_ITEM}
    >
      <S.Rank
        $matches={matches}
        $highlight={highlight}
        data-element-id={
          highlight ? ELEMENT_IDS.TYPE_HIGHLIGHT : ELEMENT_IDS.TYPE_DEFAULT
        }
      >
        {eventParticipant?.pair ||
          eventParticipant?.startingOrder ||
          eventParticipant?.bibNumber}
      </S.Rank>

      <S.Participant>
        <EventParticipant
          person={eventParticipant?.person}
          team={eventParticipant?.team}
          representing={eventParticipant?.representing}
          bibNumber={eventParticipant?.bibNumber}
          lineup={eventParticipant?.lineup}
        />
      </S.Participant>

      <S.Primary $matches={matches}>
        {eventParticipant?.startTimeDifference}
        {eventParticipant?.startPoints && `${eventParticipant?.startPoints}p`}
        {eventParticipant?.startingPoints &&
          eventParticipant?.rank &&
          `(${eventParticipant?.rank})`}
        {/* {eventParticipant?.startTimeDifference ||
          (eventParticipant?.startPoints &&
            `${eventParticipant?.startPoints}p`) ||
          `${eventParticipant?.startingPoints}p (${eventParticipant?.rank})`} */}
      </S.Primary>

      {enableToggle && (
        <>
          <S.Toggle
            $isOpen={expandedParticipants?.includes(eventParticipant?.uuid)}
          />
          {expandedParticipants.includes(eventParticipant?.uuid) && (
            <S.ExpandedParticipants $matches={matches}>
              {eventParticipant?.lineup?.map(
                ({ number, person, uuid }: NeoLineupType) => (
                  <S.ExpandedParticipant key={uuid}>
                    <S.ExpandedParticipantNumber>
                      {number}
                    </S.ExpandedParticipantNumber>
                    <S.ExpandedParticipantName>
                      <S.ExpandedParticipantPrimaryName>
                        {person?.lastName}
                      </S.ExpandedParticipantPrimaryName>
                      <S.ExpandedParticipantSecondaryName>
                        {person?.firstName}
                      </S.ExpandedParticipantSecondaryName>
                    </S.ExpandedParticipantName>
                  </S.ExpandedParticipant>
                ),
              )}
            </S.ExpandedParticipants>
          )}
        </>
      )}
    </S.EventParticipant>
  );
};

export default EventParticipants;
