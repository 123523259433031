import { EventParticipant } from '../EventParticipant/EventParticipant';
import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as S from './SeasonStandingsResults.styled';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { Skeleton, Container } from '../../chakra';
import {
  GIRO_JERSEYS,
  TDF_JERSEYS,
  VUELTA_JERSEYS,
} from '../ScopeResults/Result/Common/Jerseys/Jerseys';

interface SeasonStandingsResultsProps {
  standings: any;
  isLoading: boolean;
  competitionId: string;
}

export const SeasonStandingsResults = ({
  standings,
  isLoading,
  competitionId,
}: SeasonStandingsResultsProps) => {
  const date = new Date(standings?.metadata?.lastUpdated);
  const isValidDate = isValid(date);
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Container maxW="4xl" pt={4}>
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
        <Skeleton height="45px" mb={1} />
      </Container>
    );
  }

  return (
    <S.ContainerQuery
      data-component-id={COMPONENT_IDS.SEASON_STANDING}
      data-competition-id={competitionId}
    >
      <Container maxW="4xl" pt={4}>
        <S.HeaderJerseyIcon>
          {standings?.season?.competition?.uuid ===
          'competition-cycling-giro-ditalia'
            ? GIRO_JERSEYS[
                standings.standingInfo.type as keyof typeof GIRO_JERSEYS
              ]
            : standings?.season?.competition?.uuid ===
                'competition-cycling-tour-de-france'
              ? TDF_JERSEYS[
                  standings.standingInfo.type as keyof typeof TDF_JERSEYS
                ]
              : standings?.season?.competition?.uuid ===
                  'competition-cycling-tour-vuelta-a-espana'
                ? VUELTA_JERSEYS[
                    standings.standingInfo.type as keyof typeof VUELTA_JERSEYS
                  ]
                : null}
        </S.HeaderJerseyIcon>
        <S.Header>
          <S.Name>{`${standings?.standingInfo?.name} ${standings?.season?.name}`}</S.Name>
          {isValidDate && (
            <S.LastUpdated>{`${t('lastUpdated')}: ${format(
              date,
              'dd.MM.yyyy',
            )}`}</S.LastUpdated>
          )}
          <S.LastUpdated>{`Etter ${standings?.season?.finishedEvents?.length} av ${standings?.season?.totalEvents?.length} øvelser`}</S.LastUpdated>
        </S.Header>
        <S.Standings>
          {!standings?.standings?.length && (
            <S.NoResults>Sammenlagtresultater ikke tilgjengelig</S.NoResults>
          )}
          {standings?.standings?.map((s: any, index: number) => (
            <S.Standing key={index} data-element-id={ELEMENT_IDS.LIST_ITEM}>
              <S.Left>
                <S.Rank
                  data-element-id={
                    s?.countries?.[0]?.uuid === 'country-norway'
                      ? ELEMENT_IDS.TYPE_HIGHLIGHT
                      : ELEMENT_IDS.TYPE_DEFAULT
                  }
                >
                  {s.rank}
                </S.Rank>

                <EventParticipant
                  person={{
                    ...s?.person,
                    countries: s?.countries,
                    gender: s?.gender,
                  }}
                  team={s?.team}
                />
              </S.Left>
              <S.Right>
                {Boolean(s.points) ? (
                  <Points points={s.points} />
                ) : (
                  <Duration
                    duration={s.duration}
                    timeDifference={s.timeDifference}
                  />
                )}
              </S.Right>
            </S.Standing>
          ))}
        </S.Standings>
      </Container>
    </S.ContainerQuery>
  );
};

const Points = ({ points }: { points: number }) => {
  return <S.Points>{points}p</S.Points>;
};

const Duration = ({
  duration,
  timeDifference,
}: {
  duration: string;
  timeDifference: string;
}) => {
  if (!duration) {
    return <S.TimeDifference>{timeDifference}</S.TimeDifference>;
  }

  return <S.Duration>{duration}</S.Duration>;
};

export default SeasonStandingsResults;
