import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

export const Left = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-3);
`;

export const Right = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'records qualified result';
  grid-column-gap: var(--ntb-space-3);
  grid-row-gap: var(--ntb-space-2);

  @container scopeResults ${MEDIA_QUERY.TABLET} {
    grid-column-gap: var(--ntb-space-3);
  }
`;
