import { MEDIA_QUERY } from '@ntb-sport/constants';

import styled from 'styled-components';

export const ContainerQuery = styled.div`
  container-type: inline-size;
  container-name: container;

  background-color: var(--background-color);
`;

export const HeaderJerseyIcon = styled.div`
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: var(--ntb-space-6) 0 var(--ntb-space-4) 0;
`;

export const Name = styled.h4`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);
`;

export const LastUpdated = styled.p`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  color: var(--font-color);
`;

export const NoResults = styled.p`
  text-align: center;
  font-size: var(--ntb-fontSizes-sm);
`;

export const Standings = styled.div`
  padding: var(--ntb-space-2);
`;

export const Standing = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--ntb-space-1) 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-color);
  color: var(--font-color);
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--ntb-space-2);
`;

export const Rank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-size: var(--ntb-fontSizes-xs);
  background-color: var(--background-color);
  font-weight: var(--ntb-fontWeights-semibold);
  color: var(--font-color);
  border-radius: var(--ntb-radii-md);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
    width: 24px;
    height: 24px;
  }
`;

export const Right = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const NoStandings = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  text-align: center;

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Points = styled.div`
  font-weight: var(--ntb-fontWeights-medium);
`;
export const Duration = styled.div`
  font-weight: var(--ntb-fontWeights-medium);
`;
export const TimeDifference = styled.div`
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--base-colors-error);
`;
