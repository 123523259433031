import io from 'socket.io-client';
import { useEffect, useState } from 'react';
import { MATCH_EVENTS } from '@ntb-sport/constants';
import { QUERY_KEYS } from '@ntb-sport/constants';
import { format } from 'date-fns';

function updateObjectInArray(array: any, action: any) {
  return array?.map((item: any, index: any) => {
    if (index !== action.index) {
      return item;
    }
    return {
      ...item,
      ...action?.item,
    };
  });
}

function insertItem(array: any, action: any) {
  const newArray = array.slice();
  newArray.splice(action.index, 0, action.item);
  return newArray;
}

function removeItem(array: any, action: any) {
  const newArray = array.slice();
  newArray.splice(action.index, 1);
  return newArray;
}

interface NifsSocketProps {
  baseUrl: string;
  apiClient: any;
}

export const useNifsSocket = ({ baseUrl, apiClient }: NifsSocketProps) => {
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    const nifsSocket = new NifsSocket({
      socketBaseUrl: baseUrl,
      apiClient,
    });

    setSocket(nifsSocket);

    nifsSocket.onUpdateMatches({ apiClient });
    nifsSocket.onUpdateAllEvents({ apiClient });
    nifsSocket.onUpdateMatch({ apiClient });
  }, []);

  return socket;
};

export const useSocketJoinSport = ({
  socket,
  sportGroup,
  sportId,
  customerId,
  enabled,
}: {
  socket: any;
  sportGroup: string;
  sportId: string;
  customerId: number;
  enabled: boolean;
}) => {
  useEffect(() => {
    if (enabled) {
      socket?.joinSport({ sportGroup, sportId, customerId });

      return () => {
        socket?.leaveSport({ sportGroup, sportId, customerId });
      };
    }
    return;
  }, [socket, enabled, sportGroup, sportId, customerId]);
};

export const useSocketJoinMatch = ({
  socket,
  matchId,
  enabled,
}: {
  socket: any;
  matchId: any;
  enabled: boolean;
}) => {
  useEffect(() => {
    if (enabled) {
      socket?.joinMatch({ matchId });

      return () => {
        socket?.leaveMatch({ matchId });
      };
    }
    return;
  }, [enabled, socket, matchId]);
};

export class NifsSocket {
  matchId: any;
  sportGroup: any;
  sportId: any;
  customerId: any;
  socket: any;

  constructor({
    socketBaseUrl,
    apiClient,
  }: {
    socketBaseUrl: string;
    apiClient: any;
  }) {
    this.matchId = null;
    this.sportGroup = null;
    this.sportId = null;
    this.customerId = null;
    this.socket = io(socketBaseUrl, {
      autoConnect: true,
      transports: ['websocket'],
    });

    this.onConnect(apiClient);
    this.onDisconnect();
  }

  connect() {
    this.socket.connect();
  }

  disconnect() {
    this.socket.disconnect();
  }

  isConnected() {
    return this.socket.connected;
  }

  joinSport({
    sportGroup,
    sportId,
    customerId,
  }: {
    sportGroup: any;
    sportId: any;
    customerId: any;
  }) {
    this.socket.emit('join sport', sportId, customerId);
    this.sportGroup = sportGroup;
    this.sportId = sportId;
    this.customerId = customerId;
    console.log(
      `%c NifsSocket joined sportGroup: ${sportGroup}, sportId: ${sportId} for customer group: ${customerId}`,
      'color: green',
    );
  }

  leaveSport({
    sportGroup,
    sportId,
    customerId,
  }: {
    sportGroup: any;
    sportId: any;
    customerId: any;
  }) {
    this.socket.emit('leave sport', sportId, customerId);
    this.sportGroup = null;
    this.sportId = null;
    this.customerId = null;
    console.log(
      `%c NifsSocket left sportGroup: ${sportGroup}, sportId: ${sportId} for customer group: ${customerId}`,
      'color: red',
    );
  }

  joinMatch({ matchId }: { matchId: any }) {
    this.matchId = matchId;
    this.socket.emit('join match', matchId, null, []);
    console.log(`%c NifsSocket joined match ${matchId}.`, 'color: green');
  }

  leaveMatch({ matchId }: { matchId: any }) {
    this.matchId = null;
    this.socket.emit('leave match', matchId);
    console.log(`%c NifsSocket left match ${matchId}.`, 'color: red');
  }

  onConnect(apiClient: any) {
    this.socket.on('connect', () => {
      console.log(`%c NifsSocket connected.`, 'color: green');

      if (this.sportGroup && this.sportId && this.customerId) {
        this.joinSport({
          sportGroup: this.sportGroup,
          sportId: this.sportId,
          customerId: this.customerId,
        });
      }

      if (this.matchId) {
        this.joinMatch({ matchId: this.matchId });
      }

      this.onUpdateMatch({ apiClient });
      this.onUpdateMatches({ apiClient });
      this.onUpdateAllEvents({ apiClient });
    });
  }

  onDisconnect() {
    this.socket.on('disconnect', () => {
      console.log(`%c NifsSocket disconnected.`, 'color: red');
    });
  }

  // onUpdatePlingEvents({ updateFn }: { updateFn: any }) {
  //   this.socket.on('updateImportantEvents', (event: any) => {
  //     updateFn(event);
  //   });
  // }

  onUpdateMatches({ apiClient }: { apiClient: any }) {
    this.socket.on('updateImportantEvents', (event: any) => {
      const today = format(new Date(), 'yyyy-MM-dd');
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.NIFS_MATCHES,
        { sportGroup, date: today },
      ]);

      if (!state) return;

      const matchIndex =
        state && state.findIndex((match: any) => match.id === event.matchId);

      if (matchIndex === -1) {
        return;
      }

      const eventData = !event?.isUpdate && {
        matchEventTypeId: event?.matchEventTypeId,
        time: event?.time,
        team: event?.team,
        person: event?.person,
      };

      const newState = updateObjectInArray(state, {
        index: matchIndex,
        item: {
          ...state[matchIndex],
          matchStatusId: event.matchStatusId || state[matchIndex].matchStatusId,
          eventData,
          result: event.matchScore || state[matchIndex].result,
          timeStartFirstHalf:
            event?.match?.timeStartFirstHalf || state?.timeStartFirstHalf,
          timeStartSecondHalf:
            event?.match?.timeStartSecondHalf || state?.timeStartSecondHalf,
          timeStartFirstHalfExtraTime:
            event?.match?.timeStartFirstHalfExtraTime ||
            state?.timeStartFirstHalfExtraTime,
          timeStartSecondHalfExtraTime:
            event?.match?.timeStartSecondHalfExtraTime ||
            state?.timeStartSecondHalfExtraTime,
        },
      });

      apiClient.setQueryData(
        [QUERY_KEYS.NIFS_MATCHES, { sportGroup, date: today }],
        newState,
      );
    });
  }

  onUpdateAllEvents({ apiClient }: { apiClient: any }) {
    this.socket.on('updateImportantEvents', (event: any) => {
      const state = apiClient?.getQueryData([QUERY_KEYS.MATCH_EVENTS]);

      if (!state) return;

      if (event?.important) {
        const index = state?.data?.findIndex(
          (matchEvent: any) => matchEvent.id === event.id,
        );

        if (index !== -1) {
          const newState = {
            count: 0,
            data: updateObjectInArray(state.data, {
              index: index,
              item: {
                ...state.data[index],
                ...event,
              },
            }),
          };
          apiClient.setQueryData([QUERY_KEYS.MATCH_EVENTS], newState);
        } else {
          const updateCounter =
            event?.matchEventTypeId === MATCH_EVENTS.GOAL ||
            event?.matchEventTypeId === MATCH_EVENTS.GOAL_PENALTY_SHOOTOUT ||
            event?.matchEventTypeId === MATCH_EVENTS.MISS_PENALTY_SHOOTOUT ||
            event?.matchEventTypeId === MATCH_EVENTS.OWN_GOAL ||
            event?.matchEventTypeId === MATCH_EVENTS.PENALTY ||
            event?.matchEventTypeId === MATCH_EVENTS.RED_CARD ||
            event?.matchEventTypeId === MATCH_EVENTS.YELLOW_CARD ||
            event?.matchEventTypeId === MATCH_EVENTS.SECOND_YELLOW_CARD ||
            event?.matchEventTypeId === MATCH_EVENTS.MATCH_KICK_OFF ||
            event?.matchEventTypeId === MATCH_EVENTS.HALF_TIME ||
            event?.matchEventTypeId === MATCH_EVENTS.START_SECOND_HALF ||
            event?.matchEventTypeId ===
              MATCH_EVENTS.SECOND_HALF_ENDS_EXTRA_TIME ||
            event?.matchEventTypeId === MATCH_EVENTS.MATCH_ENDS ||
            event?.matchEventTypeId ===
              MATCH_EVENTS.START_FIRST_HALF_EXTRA_TIME ||
            event?.matchEventTypeId === MATCH_EVENTS.HALF_TIME_EXTRA_TIME ||
            event?.matchEventTypeId ===
              MATCH_EVENTS.START_SECOND_HALF_EXTRA_TIME ||
            event?.matchEventTypeId === MATCH_EVENTS.PENALTY_SHOOTOUT
              ? true
              : false;

          const newState = {
            count: updateCounter && state.count <= 50 && state.count + 1,
            data: [...state.data, event],
          };

          apiClient.setQueryData([QUERY_KEYS.MATCH_EVENTS], newState);
        }
      }
    });
  }

  onUpdateMatch({ apiClient }: { apiClient: any }) {
    this.socket.on('addLiveFeed', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      if (!state) return;

      const newState = {
        ...state,
        liveFeeds: [event],
      };
      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('deleteLiveFeed', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const newState = state;
      delete newState.liveFeeds;

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('updateMatch', (event: any) => {
      const eventId = event?.matchId?.toString() || event?.id?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const newState = {
        ...state,
        matchStatusId: event?.matchStatusId,
        timestamp: event?.timestamp,
        result: event?.result,
        homeTeam: {
          ...state?.homeTeam,
          kit: event?.homeTeam?.kit || state?.homeTeam?.kit,
        },
        awayTeam: {
          ...state?.awayTeam,
          kit: event?.awayTeam?.kit || state?.awayTeam?.kit,
        },
        isLineupConfirmed: event?.isLineupConfirmed,
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('updateMatchEvent', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const matchEvents = state?.matchEvents || [];
      const existingEventIndex = matchEvents.findIndex(
        (ev: any) => ev.id === event.id,
      );

      const newState = {
        ...state,
        timeStartFirstHalf:
          event?.match?.timeStartFirstHalf || state?.timeStartFirstHalf,
        timeStartSecondHalf:
          event?.match?.timeStartSecondHalf || state?.timeStartSecondHalf,
        timeStartFirstHalfExtraTime:
          event?.match?.timeStartFirstHalfExtraTime ||
          state?.timeStartFirstHalfExtraTime,
        timeStartSecondHalfExtraTime:
          event?.match?.timeStartSecondHalfExtraTime ||
          state?.timeStartSecondHalfExtraTime,
        matchStatusId: event?.matchStatusId,
        result: event?.matchScore,
        matchEvents:
          existingEventIndex !== -1
            ? updateObjectInArray(matchEvents, {
                index: existingEventIndex,
                item: event,
              })
            : [...matchEvents, event],
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('deleteMatchEvent', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const newState = {
        ...state,
        matchEvents: state?.matchEvents?.filter(
          (e: any) => e?.id !== Number(event?.matchEventId),
        ),
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('updateReferee', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const referees = state?.referees || [];

      const refereeIndex = referees?.findIndex(
        (referee: any) => referee?.id === event?.id,
      );

      const newState = {
        ...state,
        referees: insertItem(referees, {
          refereeIndex,
          item: event,
        }),
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('deleteReferee', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      if (Number(event?.matchId) === state?.id) {
        const refereeIndex =
          state?.referees &&
          state?.referees?.findIndex(
            (referee: any) => referee?.id === Number(event?.refereeId),
          );

        const newState = {
          ...state,
          referees: removeItem(state?.referees, {
            refereeIndex,
          }),
        };

        apiClient.setQueryData(
          [QUERY_KEYS.MATCH, { sportGroup, eventId }],
          newState,
        );
      }
    });

    this.socket.on('updateLineup', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const isHomeTeam = state?.homeTeam?.id === event?.teamId;
      const team = isHomeTeam ? 'homeTeam' : 'awayTeam';

      const personIndex = state?.[team]?.persons?.findIndex(
        (person: any) => person?.id === Number(event?.id),
      );

      const newState = {
        ...state,
        [team]: {
          ...state?.[team],
          persons:
            personIndex === -1
              ? insertItem(state?.[team]?.persons, {
                  personIndex,
                  item: event,
                })
              : updateObjectInArray(state?.[team]?.persons, {
                  index: personIndex,
                  item: event,
                }),
        },
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('deleteLineup', (event: any) => {
      const eventId = event?.matchId?.toString();
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const newState = {
        ...state,
        homeTeam: {
          ...state?.homeTeam,
          persons: state?.homeTeam?.persons?.filter(
            (person: any) => person?.id !== Number(event?.lineupId),
          ),
        },
        awayTeam: {
          ...state?.awayTeam,
          persons: state?.awayTeam?.persons?.filter(
            (person: any) => person?.id !== Number(event?.lineupId),
          ),
        },
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });

    this.socket.on('updateMatchStatistics', (event: any) => {
      const eventId = this.matchId;
      const sportGroup = this.sportGroup;

      const state = apiClient.getQueryData([
        QUERY_KEYS.MATCH,
        { sportGroup, eventId },
      ]);

      const isHomeTeam = state?.homeTeam?.id === event?.id;

      const team = isHomeTeam ? 'homeTeam' : 'awayTeam';

      if (!state) return;

      const newState = {
        ...state,
        [team]: {
          ...state?.[team],
          matchStatistics: { ...state?.[team]?.matchStatistics, ...event },
        },
      };

      apiClient.setQueryData(
        [QUERY_KEYS.MATCH, { sportGroup, eventId }],
        newState,
      );
    });
  }
}
