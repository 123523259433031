import styled from 'styled-components';

export const Jerseys = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-3);
`;

export const Jersey = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  height: 20px;
  width: 20px;

  svg {
    height: 100%;
    width: 100%;
  }
`;
