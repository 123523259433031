import * as S from './Qualified.styled';

import { LabelProperties } from 'neo-common-enums';
import { ELEMENT_IDS } from '@ntb-sport/constants';

const RESULT_IDS = {
  [LabelProperties.ResultId.TEMPORARY_LUCKY_LOOSER]: {
    name: '(LL)',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.LUCKY_LOOSER]: {
    name: 'LL',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.QUALIFIED_FOR_NEXT_ROUND]: {
    name: 'Q',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.QUALIFIED_FOR_A_FINAL]: {
    name: 'QA',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  [LabelProperties.ResultId.QUALIFIED_FOR_B_FINAL]: {
    name: 'QB',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
  125: {
    name: 'q',
    type: ELEMENT_IDS.TYPE_SUCCESS,
  },
};

export const Qualified = ({ resultId }: { resultId: number }) => {
  const displayQualified =
    resultId === LabelProperties.ResultId.LUCKY_LOOSER ||
    resultId === LabelProperties.ResultId.TEMPORARY_LUCKY_LOOSER ||
    resultId === LabelProperties.ResultId.QUALIFIED_FOR_NEXT_ROUND ||
    resultId === LabelProperties.ResultId.QUALIFIED_FOR_A_FINAL ||
    resultId === LabelProperties.ResultId.QUALIFIED_FOR_B_FINAL ||
    resultId === 125;

  if (!displayQualified) return null;

  return (
    <S.Qualified
      data-element-id={RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.type}
    >
      {RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.name}
    </S.Qualified>
  );
};

export default Qualified;
