import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

export const Qualified = styled.div`
  grid-area: qualified;
  display: flex;
  align-items: center;
  justify-self: end;

  color: var(--font-color);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  @container scopeResults ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
