import { Skeleton } from '../../../chakra';
import styled from 'styled-components';

const StyledLoader = styled.div`
  padding: var(--ntb-space-2);
`;

export const Loader = () => {
  return (
    <StyledLoader>
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
      <Skeleton height="45px" mb={1} />
    </StyledLoader>
  );
};
