import {
  SocketProvider as SocketProviderCommon,
  useSportEditSocket,
  useNifsSocket,
  useNeoSocket,
} from '@ntb-sport/socket';

import { useContext } from 'react';

import { SportEditCustomerContext } from '@ntb-sport/customer';
import { useApiClient } from '@ntb-sport/api';
import { useRouter } from 'next/router';
import { NORWEGIAN_SPORT_GROUP_TO_ENGLISH } from '@ntb-sport/constants';

export const SocketProvider = ({ children }) => {
  const apiClient = useApiClient();
  const router = useRouter();

  const { sportGroup } = router.query;

  const { customer } = useContext<any>(SportEditCustomerContext);

  const nifsSocket = useNifsSocket({
    baseUrl: process.env.NEXT_PUBLIC_NIFS_SOCKET_BASE_URL,
    apiClient,
  });

  const neoSocket = useNeoSocket({
    baseUrl: process.env.NEXT_PUBLIC_NEO_SOCKET_BASE_URL,
    sportGroup: NORWEGIAN_SPORT_GROUP_TO_ENGLISH[sportGroup as string],
    customerId: customer?.uuid,
    token: customer?.token,
    apiClient,
  });

  const sportEditSocket = useSportEditSocket({
    baseUrl: process.env.NEXT_PUBLIC_SPORTEDIT_SOCKET_BASE_URL,
    sportGroup: NORWEGIAN_SPORT_GROUP_TO_ENGLISH[sportGroup as string],
    customerId: customer?.uuid,
    token: customer?.token,
    apiClient,
  });

  return (
    <SocketProviderCommon sockets={{ nifsSocket, neoSocket, sportEditSocket }}>
      {children}
    </SocketProviderCommon>
  );
};
