import styled from 'styled-components';

interface StyledProps {
  $strike?: boolean;
}

export const StylePoints = styled.div`
  grid-area: stylePoints;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  justify-items: end;
  grid-column-gap: var(--ntb-space-1);
  align-items: center;
`;

export const StylePoint = styled.div<StyledProps>`
  width: 100%;
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  text-decoration: ${(props) => props.$strike && 'line-through'};
  color: ${(props) =>
    props.$strike
      ? 'var(--base-colors-blackAlpha-600)'
      : 'var(--base-colors-blackAlpha-900)'};
  text-decoration-color: var(--base-colors-error);
`;
