import { useQuery } from '@tanstack/react-query';

import { fetchAthlete, FetchAthleteType } from './fetch';
import { QUERY_KEYS } from '@ntb-sport/constants';

interface UseQueryNifsPerson {
  queryKey?: string;
  queryKeyParams?: Record<string, unknown>;
  params: FetchAthleteType;
  options?: Record<string, unknown>;
}

export const useQueryNifsPerson = ({
  queryKey = QUERY_KEYS.ATHLETE,
  queryKeyParams,
  params,
  options,
}: UseQueryNifsPerson) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchAthlete(params),
    enabled: Boolean(params.sportGroup) && Boolean(params.personId),
    ...options,
  });

  return { data, isLoading, error, isFetching };
};
