import * as S from './Participant.styled';
import { NeoScopeResultType } from '@ntb-sport/types';
import EventParticipant from '../../../../EventParticipant/EventParticipant';

interface ParticipantProps {
  scopeResult: NeoScopeResultType;
}

export const Participant = ({ scopeResult }: ParticipantProps) => {
  return (
    <S.Participant>
      <EventParticipant
        person={scopeResult?.participant?.person}
        team={scopeResult?.participant?.team}
        representing={scopeResult?.participant?.representing}
        bibNumber={scopeResult?.participant?.bibNumber}
        lineup={scopeResult?.participant?.lineup}
        lineupExchanger={scopeResult?.participant?.lineupExchanger}
        isPelotonParticipant={scopeResult?.participant?.isPelotonParticipant}
      />
    </S.Participant>
  );
};
