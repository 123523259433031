import { ELEMENT_IDS } from '@ntb-sport/constants';
import * as S from './Rank.styled';
import { NeoScopeResultType } from '@ntb-sport/types';
import { BronzeIcon, GoldIcon, SilverIcon } from '@ntb-sport/ui';
import { LabelProperties } from 'neo-common-enums';

function getRank(scopeTypeId?: number, isMedalEvent?: boolean, rank?: number) {
  if (
    isMedalEvent &&
    scopeTypeId === LabelProperties.ScopeTypeId.FINISH &&
    rank &&
    rank === 1
  ) {
    return <GoldIcon>{rank}</GoldIcon>;
  } else if (
    isMedalEvent &&
    scopeTypeId === LabelProperties.ScopeTypeId.FINISH &&
    rank &&
    rank === 2
  ) {
    return <SilverIcon>{rank}</SilverIcon>;
  }
  if (
    isMedalEvent &&
    scopeTypeId === LabelProperties.ScopeTypeId.FINISH &&
    rank &&
    rank === 3
  ) {
    return <BronzeIcon>{rank}</BronzeIcon>;
  } else {
    return rank;
  }
}

interface RankProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Rank = ({
  scopeResult,
  highlightedCountryUUID,
  isMedalEvent,
  scopeTypeId,
}: RankProps) => {
  const highlightCountry =
    scopeResult?.participant?.team?.uuid === highlightedCountryUUID ||
    scopeResult?.participant?.person?.countries?.[0]?.uuid ===
      highlightedCountryUUID;

  return (
    <S.Rank
      data-element-id={
        highlightCountry ? ELEMENT_IDS.TYPE_HIGHLIGHT : ELEMENT_IDS.TYPE_DEFAULT
      }
      $isMedalEvent={isMedalEvent}
    >
      {getRank(scopeTypeId, isMedalEvent, scopeResult?.rank)}
    </S.Rank>
  );
};
