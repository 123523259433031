import * as S from './EventHero.styled';

import { format, isSameDay, isValid } from 'date-fns';
import { NeoBroadcastType, NeoEventType } from '@ntb-sport/types';

import { nb, fi, sv } from 'date-fns/locale';

import { useTranslation } from 'react-i18next';

import { ImDisplay } from 'react-icons/im';

import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton, Icon } from '../../chakra';

const LOCALES = {
  nb,
  sv,
  fi,
};

interface EventHeroType {
  data: NeoEventType;
  isLoading: boolean;
  locale?: string;
  backgroundImage?: string;
  VideoPlayer?: any;
  RadioPlayer?: any;
}

export const EventHeroOld = ({
  data,
  isLoading,
  locale = 'no',
}: EventHeroType) => {
  const { t } = useTranslation();
  const fromDate = data?.fromDate && new Date(data?.fromDate);
  const toDate = data?.toDate && new Date(data?.toDate);
  const date = data?.timestamp && new Date(data?.timestamp);

  const countryName = data?.arrangedInCountries?.[0]?.name;
  const locationName = data?.arrangedAtLocations?.[0]?.name;

  return (
    <div
      data-component-id={COMPONENT_IDS.EVENT_HERO}
      data-competition-id={data?.seasons?.[0]?.competition?.uuid}
    >
      {isLoading ? (
        <Skeleton height={125} />
      ) : (
        <S.EventHero>
          <S.Container>
            <S.Competition>
              <S.CompetitionName>{`${data?.seasons?.[0]?.competition?.sport?.name} - ${data?.seasons?.[0]?.competition?.name}`}</S.CompetitionName>
            </S.Competition>
            <S.Name>
              {`${data?.name}, ${
                data?.labels?.includes('ClassicStyle')
                  ? `${t('styles.classicStyle')}, `
                  : data?.labels?.includes('FreeStyle')
                    ? `${t('styles.freeStyle')}, `
                    : ''
              } ${t(`gender.${[data?.gender?.uuid]}`)}`}
            </S.Name>
            <S.DateAndLocation>
              <S.EventDate>
                <S.EventDateIcon />
                <S.EventDateName>
                  {fromDate && toDate && !isSameDay(fromDate, toDate)
                    ? `${
                        isValid(fromDate) &&
                        format(fromDate, 'd', {
                          locale: LOCALES[locale as keyof typeof LOCALES],
                        })
                      }.-${format(toDate, 'd. MMMM yyyy', {
                        locale: LOCALES[locale as keyof typeof LOCALES],
                      })}`
                    : fromDate && toDate && isSameDay(fromDate, toDate)
                      ? `${
                          isValid(fromDate) &&
                          format(fromDate, 'd. MMMM yyyy', {
                            locale: LOCALES[locale as keyof typeof LOCALES],
                          })
                        }`
                      : `${
                          isValid(date) &&
                          format(date, 'd. MMMM yyyy', {
                            locale: LOCALES[locale as keyof typeof LOCALES],
                          })
                        }`}
                </S.EventDateName>
              </S.EventDate>
              {(countryName || locationName) && (
                <S.Location>
                  <S.LocationIcon />
                  {locationName && (
                    <S.LocationName>{`${locationName},`}</S.LocationName>
                  )}
                  <S.LocationCountry>{countryName}</S.LocationCountry>
                </S.Location>
              )}
              {Boolean(
                data?.broadcasts?.find(
                  (broadcast: NeoBroadcastType) => broadcast?.typeId === 1,
                ),
              ) && (
                <S.TvChannels>
                  <Icon as={ImDisplay} />
                  {data?.broadcasts
                    ?.filter(
                      (broadcast: NeoBroadcastType) => broadcast?.typeId === 1,
                    )
                    ?.map((broadcast: NeoBroadcastType) => (
                      <S.TvChannel key={broadcast?.broadcastingChannel?.uuid}>
                        {broadcast?.broadcastingChannel?.name}
                      </S.TvChannel>
                    ))}
                </S.TvChannels>
              )}
            </S.DateAndLocation>
          </S.Container>
        </S.EventHero>
      )}
    </div>
  );
};
