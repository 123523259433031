import styled, { css } from 'styled-components';

import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $active?: boolean;
  $isFixed?: boolean;
  $hasChildren?: boolean;
  $justifyItems?: 'start' | 'center' | 'end';
  $matches?: MatchesType;
}

export const Menu = styled.nav<StyledProps>`
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props?.$matches?.desktopS ? 'row' : 'column')};
  justify-content: ${(props) => props?.$justifyItems};
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0px -4px 0px var(--base-button-border-color);
  width: ${(props) => (props?.$matches?.desktopS ? 'auto' : '100%')};
  padding-top: ${(props) =>
    props?.$matches?.desktopS ? '0' : 'var(--ntb-space-2)'};

  ${(props) =>
    props?.$isFixed &&
    css`
      position: fixed;
      bottom: 0;
      z-index: 9999;
      box-shadow: 0px -4px 0px var(--base-button-border-color);
    `};
`;

export const MenuItem = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${(props) => (props?.$matches?.desktopS ? 'row' : 'column')};
  align-items: ${(props) => (props?.$matches?.desktopS ? 'stretch' : 'center')};
  justify-content: center;
  column-gap: var(--ntb-space-2);
  padding: ${(props) =>
    props?.$matches?.desktopS ? 'var(--ntb-space-3)' : 'var(--ntb-space-2)'};
  font-size: ${(props) =>
    props?.$matches?.desktopS
      ? 'var(--ntb-fontSizes-sm)'
      : 'var(--ntb-fontSizes-xs)'};
  font-weight: var(--ntb-fontWeights-medium);

  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: ${(props) =>
    props?.$active ? 'var(--base-button-border-color__active)' : 'transparent'};

  &:hover {
    color: var(--base-button-border-color__hover);
  }
`;
