import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

export const Rank = styled.div<{ $isMedalEvent?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-size: var(--ntb-fontSizes-xs);
  background-color: ${(props) =>
    !props.$isMedalEvent && 'var(--background-color)'};
  font-weight: var(--ntb-fontWeights-semibold);
  color: var(--font-color);
  border-radius: var(--ntb-radii-md);

  @container scopeResults ${MEDIA_QUERY.MOBILE_L} {
    width: 25px;
    height: 25px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
