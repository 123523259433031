import styled, { keyframes } from 'styled-components';

import { LabelProperties } from 'neo-common-enums';
import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $isLoading?: boolean;
  $status?: any;
  $matches?: MatchesType;
}

export const Wrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  padding: var(--ntb-space-2) 0 var(--ntb-space-4) 0;
  font-size: ${(props) =>
    props?.$matches?.tablet
      ? ' var(--ntb-fontSizes-md)'
      : ' var(--ntb-fontSizes-sm)'};

  color: var(--ntb-event-status-color);
  font-weight: var(--ntb-fontWeights-light);
  background-color: var(--background-color);
`;

export const InfoWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80vw;
  margin: 0 auto;
`;

export const Info = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-2);
  row-gap: var(--ntb-space-1);
`;

export const Name = styled.span<StyledProps>`
  font-size: ${(props) =>
    props?.$matches?.tablet
      ? ' var(--ntb-fontSizes-sm)'
      : ' var(--ntb-fontSizes-xs)'};
  font-weight: var(--ntb-fontWeights-medium);

  &::first-letter {
    text-transform: capitalize;
  }
  &:after {
    content: '-';
    margin: 0 var(--ntb-space-0-5);
  }
`;

export const Status = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
  font-size: ${(props) =>
    props?.$matches?.tablet
      ? ' var(--ntb-fontSizes-sm)'
      : ' var(--ntb-fontSizes-xs)'};
  font-weight: var(--ntb-fontWeights-light);
`;

const pulseSuccess = keyframes`
 0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 160, 33, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(33, 160, 33, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 160, 33, 0);
  }
`;

const pulseWarning = keyframes`
 0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(226, 46, 46, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px rgba(226, 46, 46, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(226, 46, 46, 0);
  }
`;

export const Pulse = styled.div<StyledProps>`
  background: ${(props) =>
    props?.$status === LabelProperties.EventStatus.INTERRUPTED ||
    props?.$status === LabelProperties.EventStatus.START_DELAYED
      ? 'rgb(226, 46, 46)'
      : props?.$status === LabelProperties.EventStatus.ONGOING
        ? 'rgb(33, 160, 33)'
        : ''};
  border-radius: 50%;
  height: 6px;
  width: 6px;
  animation-name: ${(props) =>
    props?.$status === LabelProperties.EventStatus.INTERRUPTED ||
    props?.$status === LabelProperties.EventStatus.START_DELAYED
      ? pulseWarning
      : props?.$status === LabelProperties.EventStatus.ONGOING
        ? pulseSuccess
        : ''};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Comment = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  text-align: center;
`;
