import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled, { css } from 'styled-components';
import { Layouts, ToggleVariants } from './EventInfo';

export const ContainerQuery = styled.div<{ $layout: Layouts }>`
  container-type: inline-size;
  container-name: container;

  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: ${(props) =>
    props.$layout === 'compact' ? 'row' : 'column'};
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
  row-gap: var(--ntb-space-1);
  padding: ${(props) =>
    props.$layout === 'compact' ? 'var(--ntb-space-2)' : 'var(--ntb-space-6)'};

  background-color: var(--background-color);
  color: var(--font-color);
`;

export const Bar = styled.div<{ $layout: Layouts; $enableToggle: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.$layout === 'compact' ? 'row' : 'column'};
  column-gap: var(--ntb-space-1);
  cursor: ${(props) => props.$enableToggle && 'pointer'};
`;

export const Header = styled.div<{ $layout: Layouts }>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);

  text-transform: ${(props) =>
    props.$layout === Layouts.regular && 'uppercase'};
  font-size: var(--ntb-fontSizes-xs);
  font-weight: ${(props) =>
    props.$layout === Layouts.compact
      ? 'var(--ntb-fontWeights-medium)'
      : 'var(--ntb-fontWeights-normal)'};

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const SubHeader = styled.div<{ $layout: Layouts }>`
  font-size: ${(props) =>
    props.$layout === Layouts.compact
      ? 'var(--ntb-fontSizes-xs)'
      : 'var(--ntb-fontSizes-lg)'};
  font-weight: var(--ntb-fontWeights-medium);

  &::first-letter {
    text-transform: capitalize;
  }

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: ${(props) =>
      props.$layout === Layouts.compact
        ? 'var(--ntb-fontSizes-sm)'
        : 'var(--ntb-fontSizes-xl)'};
  }
`;

export const AdditionalInfo = styled.div<{ $variant: ToggleVariants }>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);

  ${(props) =>
    props.$variant === ToggleVariants.toggle &&
    css`
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--background-color);
      row-gap: var(--ntb-space-2);
      padding: var(--ntb-space-2);
      border-bottom: 1px solid var(--border-color);
    `}
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-0-5);

  white-space: pre-wrap;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-0-5);

  white-space: pre-wrap;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Broadcasts = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-0-5);

  white-space: pre-wrap;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
