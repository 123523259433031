import { useTranslation } from 'react-i18next';

import * as S from './EventInfo.styled';
import { CalendarIcon, LocationIcon } from '../../icons';
import { getBroadcasts, getDate, getLocation, getSportIcon } from './utils';
import { UUIDs } from 'neo-common-enums';
import { useBoolean } from '@ntb-sport/hooks';
import { Button, IconButton, Image, Skeleton } from '../../chakra';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { ImDisplay } from 'react-icons/im';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';

export enum Layouts {
  regular = 'regular',
  compact = 'compact',
}

export enum Variants {
  regular = 'regular',
  brand = 'brand',
}

export enum ToggleVariants {
  regular = 'regular',
  toggle = 'toggle',
}

interface EventInfoProps {
  event: any;
  locale?: string;
  layout?: Layouts;
  enableToggle?: boolean;
  isLoading?: boolean;
  variant?: string;
}

export const EventInfo = ({
  event,
  locale = 'nb',
  layout = Layouts.regular,
  enableToggle = false,
  isLoading = false,
  variant = Variants.regular,
}: EventInfoProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useBoolean(enableToggle ? false : true);
  const [showTrackProfile, setShowTrackProfile] = useBoolean();

  const sport = event?.seasons?.[0]?.competition?.sport;
  const tour = event?.tour;
  const competition = event?.seasons?.[0]?.competition;

  const fromDate = event?.fromDate && new Date(event?.fromDate);
  const toDate = event?.toDate && new Date(event?.toDate);
  const timestamp = event?.timestamp && new Date(event?.timestamp);

  const date = getDate(fromDate, toDate, timestamp, locale);

  const style = event?.labels?.includes('ClassicStyle')
    ? `${t('styles.classicStyle')}, `
    : event?.labels?.includes('FreeStyle')
      ? `${t('styles.freeStyle')}, `
      : '';

  const label =
    sport?.uuid === UUIDs.Sport.CROSS_COUNTRY_SKIING &&
    event?.labels?.includes('IndividualStart')
      ? `${t('labels.individualStart')}, `
      : sport?.uuid === UUIDs.Sport.CROSS_COUNTRY_SKIING &&
          (event?.labels?.includes('MassStart') ||
            event?.labels?.includes('Skiathlon'))
        ? `${t('labels.massStart')}, `
        : '';

  const location = getLocation(
    event?.arrangedAtLocations,
    event?.arrangedInCountries,
  );

  const broadcasts = getBroadcasts(event?.broadcasts);

  const displayAdditionalInfo =
    (layout === Layouts.regular && !enableToggle) || (enableToggle && isOpen);

  const isCycling = sport?.uuid === UUIDs.Sport.CYCLING;

  const hasTrackProfile = Boolean(event?.stageProfile);

  return (
    <S.ContainerQuery
      $layout={layout}
      data-component-id={COMPONENT_IDS.EVENT_INFO}
      data-variant-id={variant}
    >
      <S.Bar
        $layout={layout}
        $enableToggle={enableToggle}
        onClick={setIsOpen.toggle}
      >
        <Skeleton isLoaded={!isLoading}>
          <S.Header $layout={layout}>
            {getSportIcon(sport)}
            {`${sport?.name} - ${tour?.name || competition?.name}, `}
          </S.Header>
        </Skeleton>

        <Skeleton isLoaded={!isLoading}>
          <S.SubHeader
            $layout={layout}
          >{`${event?.name}, ${style} ${label} ${t(`gender.${[event?.gender?.uuid]}`)}`}</S.SubHeader>
        </Skeleton>

        {enableToggle && !isLoading && (
          <IconButton
            size="xs"
            aria-label={isOpen ? 'Lukk' : 'Se mer'}
            data-element-id={ELEMENT_IDS.BUTTON}
            backgroundColor="var(--background-color)"
            borderColor="var(--border-color)"
            color="var(--font-color)"
            _hover={{
              bg: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              bg: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
            icon={
              isOpen ? (
                <ChevronUpIcon width={'1.5em'} height={'1.5em'} />
              ) : (
                <ChevronDownIcon width={'1.5em'} height={'1.5em'} />
              )
            }
          />
        )}
      </S.Bar>

      {(date || location || broadcasts) && displayAdditionalInfo && (
        <Skeleton isLoaded={!isLoading}>
          <S.AdditionalInfo
            $variant={
              enableToggle ? ToggleVariants.toggle : ToggleVariants.regular
            }
          >
            {date && (
              <S.Date>
                <CalendarIcon style={{ fill: 'none' }} />
                {date}
              </S.Date>
            )}
            {location && (
              <S.Location>
                <LocationIcon style={{ fill: 'none' }} />
                {location}
              </S.Location>
            )}

            {broadcasts && (
              <S.Broadcasts>
                <ImDisplay />
                {broadcasts}
              </S.Broadcasts>
            )}
          </S.AdditionalInfo>
        </Skeleton>
      )}

      {isCycling && hasTrackProfile && (
        <>
          <Button
            onClick={setShowTrackProfile.toggle}
            mt={2}
            data-element-id={ELEMENT_IDS.BUTTON}
            size="sm"
            aria-controls="calendar"
            aria-expanded={showTrackProfile}
            borderWidth="1px"
            backgroundColor="var(--background-color)"
            borderColor="var(--border-color)"
            color="var(--font-color)"
            _hover={{
              bg: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              bg: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
          >
            {showTrackProfile ? 'Skjul løypeprofil' : 'Vis løypeprofil'}
          </Button>
          {showTrackProfile && (
            <Image
              mt={2}
              src={`https://api.sportedit.ntb.no/images/${event?.stageProfile?.uuid}`}
            />
          )}
        </>
      )}
    </S.ContainerQuery>
  );
};
