import { LabelProperties, UUIDs } from 'neo-common-enums';

export const QUERY_KEYS = {
  CUSTOMER_SETTINGS: 'CUSTOMER_SETTINGS',
  CUSTOMER_SETUP: 'CUSTOMER_SETUP',
  DAYS_WITH_EVENTS_IN_MONTH: 'DAYS_WITH_EVENTS_IN_MONTH',
  MATCH: 'MATCH',
  STADIUM: 'STADIUM',
  NIFS_COUNTRIES: 'NIFS_COUNTRIES',
  NIFS_FAIR_PLAY_TABLE: 'NIFS_FAIR_PLAY_TABLE',
  NIFS_TOURNAMENTS: 'NIFS_TOURNAMENTS',
  NIFS_TOURNAMENT_STAGES: 'NIFS_TOURNAMENT_STAGES',
  NIFS_STAGE_MATCHES: 'NIFS_STAGE_MATCHES',
  NIFS_MATCHES: 'NIFS_MATCHES',
  NIFS_TEAMS: 'NIFS_TEAMS',
  NIFS_TEAM: 'NIFS_TEAM',
  NIFS_TEAM_FIXTURES: 'NIFS_TEAM_FIXTURES',
  NIFS_TEAM_STAGES: 'NIFS_TEAM_STAGES',
  NIFS_STAGE_FIXTURES: 'NIFS_STAGE_FIXTURES',
  EVENTS: 'EVENTS',
  EVENT: 'EVENT',
  SPORT_COMPETITIONS: 'SPORT_COMPETITIONS',
  COMPETITION_SEASONS: 'COMPETITION_SEASONS',
  EVENT_STANDINGS: 'EVENT_STANDINGS',
  ATHLETICS_COMBINED_SCOPES: 'ATHLETICS_COMBINED_SCOPES',
  SCOPES: 'SCOPES',
  EVENT_PARTICIPANTS: 'EVENT_PARTICIPANTS',
  SCOPE_RESULTS: 'SCOPE_RESULTS',
  ALL_SCOPE_RESULTS: 'ALL_SCOPE_RESULTS',
  WORLD_CUP_STANDINGS: 'WORLD_CUP_STANDINGS',
  TOUR: 'TOUR',
  MEDAL_TABLES: 'MEDAL_TABLES',
  MEDAL_TABLE: 'MEDAL_TABLE',
  MEDAL_TABLE_COUNTRY: 'MEDAL_TABLE_COUNTRY',
  COMPETITIONS: 'COMPETITIONS',
  COMPETITION: 'COMPETITION',
  STAGES: 'STAGES',
  STAGE_STANDINGS: 'STAGE_STANDINGS',
  STAGE_FIXTURES: 'STAGE_FIXTURES',
  STAGE_STATISTICS: 'STAGE_STATISTICS',
  TEAMS: 'TEAMS',
  TEAM: 'TEAM',
  TEAM_FIXTURES: 'TEAM_FIXTURES',
  TEAM_STAGES: 'TEAM_STAGES',
  MATCH_EVENTS: 'MATCH_EVENTS',
  ATHLETES: 'ATHLETES',
  ATHLETE: 'ATHLETE',
  MATCH_VIDEOS: 'MATCH_VIDEOS',
  MATCH_EVENT_VIDEOS: 'MATCH_EVENT_VIDEOS',
  ALL_STANDINGS: 'ALL_STANDINGS',
  STANDINGS: 'STANDINGS',
  ALL_SHOT_SERIES: 'ALL_SHOT_SERIES',
  TOUR_STANDINGS: 'TOUR_STANDINGS',
  SEASON_STANDINGS: 'SEASON_STANDINGS',
  SEASON_STANDING: 'SEASON_STANDING',
  EVENT_PARTICIPANTS_BY_COUNTRY_AND_DATE:
    'EVENT_PARTICIPANTS_BY_COUNTRY_AND_DATE',
  SPORTS: 'SPORTS',
  SEASON_EVENTS: 'SEASON_EVENTS',
  NRK_VIDEOS: 'NRK_VIDEOS',
};

export const ELEMENT_IDS = {
  BUTTON: 'button',
  ICON: 'icon',
  CONTAINER: 'container',
  HEADER: 'header',
  FOOTER: 'footer',
  SUMMARY: 'summary',
  TOURNAMENT: 'tournament',
  COMPETITION: 'competition',
  SPORT: 'sport',
  STAGE: 'stage',
  MATCH: 'match',
  EVENT: 'event',
  LOGO: 'logo',
  IMAGE: 'image',
  TITLE: 'title',
  MENU: 'menu',
  LIST: 'list',
  LIST_ITEM: 'list-item',
  ROW: 'row',
  PITCH: 'pitch',
  OVERLAY: 'overlay',
  SCORE: 'score',
  TYPE_DEFAULT: 'default',
  TYPE_TIME: 'time',
  TYPE_SUCCESS: 'success',
  ['TYPE_SUCCESS-MINOR']: 'success-minor',
  TYPE_ERROR: 'error',
  ['TYPE_ERROR-MINOR']: 'error-minor',
  TYPE_HIGHLIGHT: 'highlight',
  TYPE_WARNING: 'warning',
};

export const COMPONENT_IDS = {
  LIVECENTER_HEADER: 'livecenter-header',
  STAGE_HERO: 'stage-hero',
  DRAWER: 'drawer',
  NOTIFICATIONS: 'notifications',
  SPORTS_MENU: 'sports-menu',
  ATHLETE_HERO: 'athlete-hero',
  COMPETITION_HERO: 'competition-hero',
  COUNTRY_MEDALS: 'country-medals',
  CUSTOMER_FEEDBACK: 'customer-feedback',
  EXAMPLE_COMPONENT_ONE: 'example-component-one',
  EXAMPLE_COMPONENT_TWO: 'example-component-two',
  FAIR_PLAY_STANDINGS: 'fair-play-standings',
  TEAM_CALENDAR: 'team-calendar',
  TEAM_FIXTURES: 'team-fixtures',
  TEAM_LINEUP: 'team-lineup',
  TEAM_MATCH_REPORT: 'team-match-report',
  TEAM_MATCH_RESULT: 'team-match-result',
  TEAM_MATCH_STATISTICS: 'team-match-statistics',
  TEAM_PLAYER_STATS: 'team-player-stats',
  TEAM_STANDINGS: 'team-standings',
  ABBREVIATION_INFO: 'abbreviation-info',
  COMBINED_EVENTS_STANDINGS: 'combined-events-standings',
  EVENT_MAIN_MENU: 'event-main-menu',
  EVENT_HERO: 'event-hero',
  EVENT_MENU: 'event-menu',
  EVENT_PARTICIPANTS: 'event-participants',
  EVENT_STATUS: 'event-status',
  MEDAL_TABLE: 'medal-table',
  SCOPE_MENU: 'scope-menu',
  SCROLL_MENU: 'scroll-menu',
  SCOPE_RESULTS: 'scope-results',
  SEASON_STANDING: 'season-standings',
  SPORTEDIT_CALENDAR: 'sportedit-calendar',
  TOUR_STANDINGS: 'tour-standings',
  SPORT_CALENDAR: 'sport-calendar',
  TAB_MENU: 'tab-menu',
  DATE_SELECTOR: 'date-selector',
  TEAM_FIXTURES_MATCH: 'team-fixtures-match',
  TEAM_FIXTURES_TEAM: 'team-fixtures-team',
  MATCH_INFO: 'match-info',
  VIDEO_HERO: 'video-hero',
  RADIO_HERO: 'radio-hero',
  LIVE_FEED: 'live-feed',
  EVENT_INFO: 'event-info',
};

export const DATA_PROVIDERS = {
  DATE_SELECTOR: 'dateSelector',
  NIFS_COUNTRIES: 'nifsCountries',
  NIFS_DISTRICTS: 'nifsDistricts',
  NIFS_MATCHES: 'nifsMatches',
  NIFS_MATCHES_BY_DATE: 'nifsMatchesByDate',
  NIFS_MATCH_SELECTOR: 'nifsMatchSelector',
  NIFS_SEARCH: 'nifsSearch',
  NIFS_STAGES: 'nifsStages',
  NIFS_TOURNAMENTS: 'nifsTournaments',
  NIFS_TEAM: 'nifsTeams',
  NEO_SPORTS: 'neoSports',
  NEO_COMPETITIONS: 'neoCompetitions',
  NEO_EVENTS: 'neoEvents',
  NEO_MEDAL_TABLES: 'neoMedalTables',
  NEO_SEARCH: 'neoSearch',
  NEO_SEASONS: 'neoSeasons',
  NEO_SEASON_EVENTS: 'neoSeasonEvents',
  SPORT_GROUP: 'sportGroup',
  CHANNEL_IDS: 'channelIds',
};

export const DATA_PICKER_GROUPS = {
  DATE_GROUP: 'dateGroup',
  NIFS_MATCH_GROUP: 'nifsMatchGroup',
  NIFS_STAGE_GROUP: 'nifsStageGroup',
  NIFS_DISTRICT_GROUP: 'nifsDistrictGroup',
  NIFS_TEAM_GROUP: 'nifsTeamGroup',
  NEO_COMPETITION_GROUP: 'neoCompetitionGroup',
  NEO_EVENT_GROUP: 'neoEventGroup',
  NEO_MEDAL_TABLE_GROUP: 'neoMedalTableGroup',
};

export const MATCH_EVENTS = {
  COMMENT: 1,
  GOAL: 2,
  RED_CARD: 3,
  YELLOW_CARD: 4,
  ASSIST: 5,
  OFFSIDE: 6,
  OWN_GOAL: 8,
  PENALTY: 9,
  PENALTY_GOAL: 10,
  PENALTY_MISS: 11,
  SECOND_YELLOW_CARD: 12,
  DISALLOWED_GOAL: 13,
  MATCH_KICK_OFF: 14,
  HALF_TIME: 15,
  MATCH_ENDS: 16,
  SECOND_HALF_ENDS_EXTRA_TIME: 17,
  HALF_TIME_EXTRA_TIME: 18,
  PENALTY_SHOOTOUT: 19,
  END_SECOND_HALF_EXTRA_TIME: 20,
  PLAYER_SUBSTITUTED: 23,
  SUBSTITUTE_ON: 24,
  CORNER: 25,
  START_SECOND_HALF: 26,
  CORNER_GOAL: 27,
  START_FIRST_HALF_EXTRA_TIME: 28,
  START_SECOND_HALF_EXTRA_TIME: 29,
  GOAL_PENALTY_SHOOTOUT: 30,
  MISS_PENALTY_SHOOTOUT: 31,
  PENALTY_PENALTIES_SHOOTOUT: 32,
  SCORE_CHANGED: 33,
  FREE_KICK: 41,
  LINEUP_READY: 43,
  WITHDRAWN_RED_CARD: 44,
  WITHDRAWN_YELLOW_CARD: 45,
  WITHDRAWN_SECOND_YELLOW_CARD: 46,
  SECOND_YELLOW_CARD_CHANGED_TO_FIRST_YELLOW_CARD: 47,
  INDIRECT_ASSIST: 48,
  TV2_VIDEO: 49,
  TWO_MINUTES_SUSPENSION: 100,
  FIVE_MINUTES_SUSPENSION: 101,
  TEN_MINUTES_SUSPENSION: 102,
  SECOND_HALF_TIME_EXTRA_TIME: 104,
  START_THIRD_HALF_EXTRA_TIME: 105,
  THIRD_HALF_TIME_EXTRA_TIME: 106,
  START_FOURTH_HALF_EXTRA_TIME: 107,
  SECOND_HALF_TIME: 110,
  START_THIRD_HALF: 111,
  BLUE_CARD: 112,
  MATCH_PENALTY: 113,
  FOURTH_HALF_TIME_EXTRA_TIME: 114,
  START_FIFTH_HALF_EXTRA_TIME: 115,
  FIFTH_HALF_TIME_EXTRA_TIME: 116,
  START_SIXTH_HALF_EXTRA_TIME: 117,
  SIXTH_HALF_TIME_EXTRA_TIME: 118,
  START_SEVENTH_HALF_EXTRA_TIME: 119,
  SEVENTH_HALF_TIME_EXTRA_TIME: 120,
  START_EIGHTH_HALF_EXTRA_TIME: 121,
  VAR: 123,
  PENALTY_OFFENCE: 124,
  PENALTY_FOULED: 125,
  ADDED_TIME: 126,
  PENALTY_SAVE: 130,
  SUMMARY: 500,
  HIGHLIGHT_VIDEO: 667,
};

export enum MATCH_STATUS_ID {
  PLAYED = 1,
  NOT_STARTED = 2,
  POSTPONED = 3,
  ABANDONED = 4,
  WILL_NOT_BE_PLAYED = 5,
  DATE_NOT_SET = 6,
  ONGOING = 7,
  FIRST_HALF = 8,
  HALF_TIME = 9,
  SECOND_HALF = 10,
  FIRST_HALF_EXTRA_TIME = 11,
  SECOND_HALF_EXTRA_TIME = 12,
  PENALTY_SHOOTOUT = 13,
  PAUSE_BEFORE_EXTRA_TIME = 14,
  HALF_TIME_EXTRA_TIME = 15,
  CANCELLED = 16,
  SECOND_HALF_TIME_EXTRA_TIME = 18,
  THIRD_HALF_EXTRA_TIME = 19,
  THIRD_HALF_TIME_EXTRA_TIME = 20,
  FOURTH_HALF_EXTRA_TIME = 21,
  THIRD_HALF = 22,
  FOURTH_HALF_TIME_EXTRA_TIME = 23,
  FIFTH_HALF_EXTRA_TIME = 24,
  FIFTH_HALF_TIME_EXTRA_TIME = 25,
  SIXTH_HALF_EXTRA_TIME = 26,
  SIXTH_HALF_TIME_EXTRA_TIME = 27,
  SEVENTH_HALF_EXTRA_TIME = 28,
  SEVENTH_HALF_TIME_EXTRA_TIME = 29,
  EIGHTH_HALF_EXTRA_TIME = 30,
  SECOND_HALF_TIME = 31,
  TABLE_SIMULATOR_PLAYED = 600,
}

export const MATCH_STATUS = {
  PLAYED: 1,
  NOT_STARTED: 2,
  POSTPONED: 3,
  ABANDONED: 4,
  WILL_NOT_BE_PLAYED: 5,
  DATE_NOT_SET: 6,
  ONGOING: 7,
  FIRST_HALF: 8,
  HALF_TIME: 9,
  SECOND_HALF: 10,
  FIRST_HALF_EXTRA_TIME: 11,
  SECOND_HALF_EXTRA_TIME: 12,
  PENALTY_SHOOTOUT: 13,
  PAUSE_BEFORE_EXTRA_TIME: 14,
  HALF_TIME_EXTRA_TIME: 15,
  CANCELLED: 16,
  SECOND_HALF_TIME_EXTRA_TIME: 18,
  THIRD_HALF_EXTRA_TIME: 19,
  THIRD_HALF_TIME_EXTRA_TIME: 20,
  FOURTH_HALF_EXTRA_TIME: 21,
  THIRD_HALF: 22,
  FOURTH_HALF_TIME_EXTRA_TIME: 23,
  FIFTH_HALF_EXTRA_TIME: 24,
  FIFTH_HALF_TIME_EXTRA_TIME: 25,
  SIXTH_HALF_EXTRA_TIME: 26,
  SIXTH_HALF_TIME_EXTRA_TIME: 27,
  SEVENTH_HALF_EXTRA_TIME: 28,
  SEVENTH_HALF_TIME_EXTRA_TIME: 29,
  EIGHTH_HALF_EXTRA_TIME: 30,
  SECOND_HALF_TIME: 31,
  TABLE_SIMULATOR_PLAYED: 600,
};

export const RESULT_IDS = {
  [LabelProperties.ResultId.DID_NOT_START]: {
    name: 'DNS',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH_FIRST_RUN]: {
    name: 'DNF',
    type: 'error',
  },
  [LabelProperties.ResultId.DID_NOT_FINISH_SECOND_RUN]: {
    name: 'DNF',
    type: 'error',
  },
  204: {
    name: 'Veltet',
    type: 'error',
  },

  [LabelProperties.ResultId.DISQUALIFIED]: { name: 'DSQ', type: 'error' },
  [LabelProperties.ResultId.LAPPED]: { name: 'LPD', type: 'error' },
  [LabelProperties.ResultId.NOT_PERMITTED_TO_START]: {
    name: 'NPS',
    type: 'error',
  },
  [LabelProperties.ResultId.NO_RESULT]: {
    name: 'X',
    type: 'error',
  },
  60: {
    name: 'X',
    type: 'normal',
  },
  61: {
    name: 'O',
    type: 'normal',
  },
  62: {
    name: 'XX',
    type: 'normal',
  },
  63: {
    name: 'XO',
    type: 'normal',
  },
  64: {
    name: 'XXX',
    type: 'normal',
  },
  65: {
    name: 'XXO',
    type: 'normal',
  },
};

// export enum MATCH_STATUS_STRING {
//   PLAYED = 'Slutt',
//   NOT_STARTED = 'Ikke startet',
//   POSTPONED = 'Utsatt',
//   ABANDONED = 'Avbrutt',
//   WILL_NOT_BE_PLAYED = 'Vil ikke bli spilt',
//   ONGOING = 'Pågår',
//   FIRST_HALF = '1. omg',
//   HALF_TIME = 'Pause',
//   SECOND_HALF = '2.omg',
//   FIRST_HALF_EXTRA_TIME = '1. ekstraomgang',
//   SECOND_HALF_EXTRA_TIME = '2. ekstraomgang',
//   PENALTY_SHOOTOUT = 'Straffesparkkonkurranse',
//   PAUSE_BEFORE_EXTRA_TIME = 'Pause før ekstraomganger',
//   HALF_TIME_EXTRA_TIME = 'Pause ekstraomganger',
//   CANCELLED = 'Kansellert',
//   SECOND_HALF_TIME_EXTRA_TIME = '',
//   THIRD_HALF_EXTRA_TIME = '',
//   THIRD_HALF_TIME_EXTRA_TIME = '',
//   FOURTH_HALF_EXTRA_TIME = '',
//   THIRD_HALF = '',
//   FOURTH_HALF_TIME_EXTRA_TIME = '',
//   FIFTH_HALF_EXTRA_TIME = '',
//   FIFTH_HALF_TIME_EXTRA_TIME = '',
//   SIXTH_HALF_EXTRA_TIME = '',
//   SIXTH_HALF_TIME_EXTRA_TIME = '',
//   SEVENTH_HALF_EXTRA_TIME = '',
//   SEVENTH_HALF_TIME_EXTRA_TIME = '',
//   EIGHTH_HALF_EXTRA_TIME = '',
//   SECOND_HALF_TIME = '',
//   TABLE_SIMULATOR_PLAYED = '',
// }

export const MATCH_TYPE = {
  FINAL: 1,
  BRONZE_FINAL: 2,
  SEMI_FINAL: 3,
  QUARTER_FINAL: 4,
  ROUND_OF_16: 5,
  ROUND_OF_32: 25,
};

export const STAGE_TYPE = {
  LEAGUE: 1,
  CUP: 2,
  OPEN: 3,
  LEAGUE_WITH_PENALTIES: 4,
  PLACEHOLDER: 5,
};

export const MATCH_TYPE_NAME = {
  [MATCH_TYPE.FINAL]: 'Finale',
  [MATCH_TYPE.SEMI_FINAL]: 'Semifinale',
  [MATCH_TYPE.BRONZE_FINAL]: 'Bronsefinale',
  [MATCH_TYPE.QUARTER_FINAL]: 'Kvartfinale',
  [MATCH_TYPE.ROUND_OF_16]: '8-delsfinale',
  [MATCH_TYPE.ROUND_OF_32]: '16-delsfinale',
  round1: 'Runde 1',
  round2: 'Runde 2',
  round3: 'Runde 3',
  round4: 'Runde 4',
  round5: 'Runde 5',
  round6: 'Runde 6',
  round7: 'Runde 7',
  round8: 'Runde 8',
  round9: 'Runde 9',
  round10: 'Runde 10',
  round11: 'Runde 11',
  round12: 'Runde 12',
  round13: 'Runde 13',
  round14: 'Runde 14',
  round15: 'Runde 15',
  round16: 'Runde 16',
  round17: 'Runde 17',
  round18: 'Runde 18',
  round19: 'Runde 19',
  round20: 'Runde 20',
  round21: 'Runde 21',
  round22: 'Runde 22',
  round23: 'Runde 23',
  round24: 'Runde 24',
  round25: 'Runde 25',
  round26: 'Runde 26',
  round27: 'Runde 27',
  round28: 'Runde 28',
  round29: 'Runde 29',
  round30: 'Runde 30',
  round31: 'Runde 31',
  round32: 'Runde 32',
  round33: 'Runde 33',
  round34: 'Runde 34',
  round35: 'Runde 35',
  round36: 'Runde 36',
  round37: 'Runde 37',
  round38: 'Runde 38',
  round39: 'Runde 39',
  round40: 'Runde 40',
  round41: 'Runde 41',
  round42: 'Runde 42',
  round43: 'Runde 43',
  round44: 'Runde 44',
  round45: 'Runde 45',
  round46: 'Runde 46',
  round47: 'Runde 47',
  round48: 'Runde 48',
  round49: 'Runde 49',
};

export const COMPETITION_TYPE = {
  NATIONAL_LEAGUE: 1,
  INTERNATIONAL_CUP: 2,
  NATIONAL_CUP: 3,
  INTERNATIONAL_CUP_QUALIFICATION: 13,
  INTERNATIONAL_TOURNAMENT: 30,
  INTERNATIONAL_REGIONAL_TOURNAMENT: 31,
  INTERNATIONAL_TOURNAMENT_QUALIFICATION: 32,
  INTERNATIONAL_REGIONAL_TOURNAMENT_QUALIFICATION: 33,
};

export const SPORT_ID = {
  FOOTBALL: 1,
  ICE_HOCKEY: 2,
  HANDBALL: 3,
  BANDY: 4,
  ALPINE: UUIDs.Sport.ALPINE,
  CROSS_COUNTRY_SKIING: UUIDs.Sport.CROSS_COUNTRY_SKIING,
  BIATHLON: UUIDs.Sport.BIATHLON,
  SKI_JUMPING: UUIDs.Sport.SKI_JUMPING,
  NORDIC_COMBINED: UUIDs.Sport.NORDIC_COMBINED,
  SPEED_SKATING: UUIDs.Sport.SPEED_SKATING,
  CURLING: UUIDs.Sport.CURLING,
  BOBSLEIGH: UUIDs.Sport.BOBSLEIGH,
  LUGE: UUIDs.Sport.LUGE,
  SKELETON: UUIDs.Sport.SKELETON,
  FREESTYLE_SKIING: UUIDs.Sport.FREESTYLE_SKIING,
  SNOWBOARDING: UUIDs.Sport.SNOWBOARDING,
  SHORT_TRACK_SPEED_SKATING: UUIDs.Sport.SHORT_TRACK_SPEED_SKATING,
  FIGURE_SKATING: UUIDs.Sport.FIGURE_SKATING,
  ATHLETICS: UUIDs.Sport.ATHLETICS,
  CYCLING: UUIDs.Sport.CYCLING,
  SUMMER_OLYMPICS: 'summer-olympics',
};

export const NORWEGIAN_SPORT_ID = {
  FOOTBALL: 1,
  ICE_HOCKEY: 2,
  HANDBALL: 3,
  BANDY: 4,
  ALPINE: 'alpint',
  CROSS_COUNTRY_SKIING: 'langrenn',
  BIATHLON: 'skiskyting',
  SKI_JUMPING: 'hopp',
  NORDIC_COMBINED: 'kombinert',
  SPEED_SKATING: 'skoyter',
  CURLING: UUIDs.Sport.CURLING,
  BOBSLEIGH: UUIDs.Sport.BOBSLEIGH,
  LUGE: UUIDs.Sport.LUGE,
  SKELETON: UUIDs.Sport.SKELETON,
  FREESTYLE_SKIING: UUIDs.Sport.FREESTYLE_SKIING,
  SNOWBOARDING: UUIDs.Sport.SNOWBOARDING,
  SHORT_TRACK_SPEED_SKATING: UUIDs.Sport.SHORT_TRACK_SPEED_SKATING,
  FIGURE_SKATING: UUIDs.Sport.FIGURE_SKATING,
  ATHLETICS: 'friidrett',
  CYCLING: 'sykling',
};

export const NORWEGIAN_SPORT_GROUP = {
  FOOTBALL: 'fotball',
  ICE_HOCKEY: 'ishockey',
  HANDBALL: 'handball',
  BANDY: 'bandy',
  WINTERSPORTS: 'vintersport',
  ATHLETICS: 'friidrett',
  CYCLING: 'sykling',
};

export const SPORT_GROUP = {
  FOOTBALL: 'football',
  ICE_HOCKEY: 'icehockey',
  HANDBALL: 'handball',
  BANDY: 'bandy',
  WINTERSPORTS: 'wintersports',
  ATHLETICS: 'athletics',
  CYCLING: 'cycling',
  SUMMER_OLYMPICS: 'summer-olympics',
};

export const NORWEGIAN_SPORT_GROUP_TO_ENGLISH = {
  fotball: 'football',
  ishockey: 'icehockey',
  handball: 'handball',
  bandy: 'bandy',
  vintersport: 'wintersports',
  friidrett: 'athletics',
  sykling: 'cycling',
  ['sommer-ol']: 'summer-olympics',
};

export const ENGLISH_SPORT_GROUP_TO_NORWEGIAN = {
  football: 'fotball',
  icehockey: 'ishockey',
  handball: 'handball',
  bandy: 'bandy',
  wintersports: 'vintersport',
  athletics: 'friidrett',
  cycling: 'sykling',
  ['summer-olympics']: 'sommer-ol',
};

export const ENGLISH_SPORT_GROUP_TO_NORWEGIAN_DISPLAY_NAME = {
  football: 'fotball',
  icehockey: 'ishockey',
  handball: 'håndball',
  bandy: 'bandy',
  wintersports: 'vintersport',
  athletics: 'friidrett',
  cycling: 'sykling',
  ['summer-olympics']: 'sommer-ol',
};

export const DEFAULT_SPORT_ID_BY_SPORT_GROUP = {
  [SPORT_GROUP.FOOTBALL]: 1,
  [SPORT_GROUP.ICE_HOCKEY]: 2,
  [SPORT_GROUP.HANDBALL]: 3,
  [SPORT_GROUP.BANDY]: 4,
  [SPORT_GROUP.WINTERSPORTS]: 'langrenn',
  [SPORT_GROUP.ATHLETICS]: 'friidrett',
  [SPORT_GROUP.CYCLING]: 'sykling',
  [SPORT_GROUP.SUMMER_OLYMPICS]: 'summer-olympics',
};

export const NORWEGIAN_SPORT_GROUP_TO_NIFS_SPORT_ID = {
  fotball: 1,
  ishockey: 2,
  handball: 3,
  bandy: 4,
};

export const SPORT_GROUP_TO_NIFS_SPORT_ID = {
  football: 1,
  icehockey: 2,
  handball: 3,
  bandy: 4,
};

export const ENGLISH_STATISTICS_TYPE_TO_NORWEGIAN_LABEL = {
  goals: 'Mål',
  cards: 'Kort',
  assists: 'Målgivende',
};

export const SPORT_ID_BELONGS_TO_SPORT_GROUP = {
  [SPORT_ID.FOOTBALL]: SPORT_GROUP.FOOTBALL,
  [SPORT_ID.ICE_HOCKEY]: SPORT_GROUP.ICE_HOCKEY,
  [SPORT_ID.HANDBALL]: SPORT_GROUP.HANDBALL,
  [SPORT_ID.BANDY]: SPORT_GROUP.BANDY,
  [SPORT_ID.ALPINE]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.CROSS_COUNTRY_SKIING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.BIATHLON]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SKI_JUMPING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.NORDIC_COMBINED]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SPEED_SKATING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.CURLING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.BOBSLEIGH]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.LUGE]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SKELETON]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.FREESTYLE_SKIING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SNOWBOARDING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.SHORT_TRACK_SPEED_SKATING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.FIGURE_SKATING]: SPORT_GROUP.WINTERSPORTS,
  [SPORT_ID.ATHLETICS]: SPORT_GROUP.ATHLETICS,
  [SPORT_ID.CYCLING]: SPORT_GROUP.CYCLING,
};

export const SPORT_ID_NORWEGIAN = {
  [SPORT_ID.FOOTBALL]: 'fotball',
  [SPORT_ID.ICE_HOCKEY]: 'ishockey',
  [SPORT_ID.HANDBALL]: 'handball',
  [SPORT_ID.BANDY]: 'bandy',
  [SPORT_ID.BIATHLON]: 'skiskyting',
  [SPORT_ID.CROSS_COUNTRY_SKIING]: 'langrenn',
  [SPORT_ID.ALPINE]: 'alpint',
  [SPORT_ID.SKI_JUMPING]: 'hopp',
  [SPORT_ID.NORDIC_COMBINED]: 'kombinert',
  [SPORT_ID.SPEED_SKATING]: 'skoyter',
  [SPORT_ID.CURLING]: 'curling',
  [SPORT_ID.BOBSLEIGH]: 'bob',
  [SPORT_ID.LUGE]: 'aking',
  [SPORT_ID.SKELETON]: 'skeleton',
  [SPORT_ID.FREESTYLE_SKIING]: 'friski',
  [SPORT_ID.SNOWBOARDING]: 'snowboard',
  [SPORT_ID.SHORT_TRACK_SPEED_SKATING]: 'kortbane-skoyter',
  [SPORT_ID.FIGURE_SKATING]: 'kunstlop',
  [SPORT_ID.ATHLETICS]: 'friidrett',
  [SPORT_ID.CYCLING]: 'sykling',
  [SPORT_ID.SUMMER_OLYMPICS]: 'sommer-ol',
};

export const SPORT_ID_TO_NORWEGIAN_NAME = {
  [SPORT_ID.FOOTBALL]: 'Fotball',
  [SPORT_ID.ICE_HOCKEY]: 'Ishockey',
  [SPORT_ID.HANDBALL]: 'Håndball',
  [SPORT_ID.BANDY]: 'Bandy',
  [SPORT_ID.BIATHLON]: 'Skiskyting',
  [SPORT_ID.CROSS_COUNTRY_SKIING]: 'Langrenn',
  [SPORT_ID.ALPINE]: 'Alpint',
  [SPORT_ID.SKI_JUMPING]: 'Hopp',
  [SPORT_ID.NORDIC_COMBINED]: 'Kombinert',
  [SPORT_ID.SPEED_SKATING]: 'Skøyter',
  [SPORT_ID.CURLING]: 'Curling',
  [SPORT_ID.BOBSLEIGH]: 'Bob',
  [SPORT_ID.LUGE]: 'Aking',
  [SPORT_ID.SKELETON]: 'Skeleton',
  [SPORT_ID.FREESTYLE_SKIING]: 'Friski',
  [SPORT_ID.SNOWBOARDING]: 'Snowboard',
  [SPORT_ID.SHORT_TRACK_SPEED_SKATING]: 'Kortbane-skøyter',
  [SPORT_ID.FIGURE_SKATING]: 'Kunstløp',
  [SPORT_ID.ATHLETICS]: 'Friidrett',
  [SPORT_ID.CYCLING]: 'Sykling',
  [SPORT_ID.SUMMER_OLYMPICS]: 'Sommer-OL',
};

export const WINTERSPORTS = [
  SPORT_ID.BIATHLON,
  SPORT_ID.CROSS_COUNTRY_SKIING,
  SPORT_ID.ALPINE,
  SPORT_ID.SKI_JUMPING,
  SPORT_ID.NORDIC_COMBINED,
  SPORT_ID.SPEED_SKATING,
  SPORT_ID.CURLING,
  SPORT_ID.BOBSLEIGH,
  SPORT_ID.LUGE,
  SPORT_ID.SKELETON,
  SPORT_ID.FREESTYLE_SKIING,
  SPORT_ID.SNOWBOARDING,
  SPORT_ID.SHORT_TRACK_SPEED_SKATING,
  SPORT_ID.FIGURE_SKATING,
];

export const SPORT_ID_NORWEGIAN_TO_UUID = {
  fotball: SPORT_ID.FOOTBALL,
  ishockey: SPORT_ID.ICE_HOCKEY,
  handball: SPORT_ID.HANDBALL,
  bandy: SPORT_ID.BANDY,
  skiskyting: SPORT_ID.BIATHLON,
  langrenn: SPORT_ID.CROSS_COUNTRY_SKIING,
  alpint: SPORT_ID.ALPINE,
  hopp: SPORT_ID.SKI_JUMPING,
  kombinert: SPORT_ID.NORDIC_COMBINED,
  skoyter: SPORT_ID.SPEED_SKATING,
  curling: SPORT_ID.CURLING,
  bob: SPORT_ID.BOBSLEIGH,
  aking: SPORT_ID.LUGE,
  skeleton: SPORT_ID.SKELETON,
  friski: SPORT_ID.FREESTYLE_SKIING,
  snowboard: SPORT_ID.SNOWBOARDING,
  'kortbane-skoyter': SPORT_ID.SHORT_TRACK_SPEED_SKATING,
  kunstlop: SPORT_ID.FIGURE_SKATING,
  friidrett: SPORT_ID.ATHLETICS,
  sykling: SPORT_ID.CYCLING,
};

export const NIFS_SPORT_GROUP_NORWEGIAN_TO_SPORT_ID = {
  fotball: SPORT_ID.FOOTBALL,
  ishockey: SPORT_ID.ICE_HOCKEY,
  handball: SPORT_ID.HANDBALL,
  bandy: SPORT_ID.BANDY,
};

export const NIFS_SPORT_GROUP_TO_SPORT_ID = {
  football: SPORT_ID.FOOTBALL,
  icehockey: SPORT_ID.ICE_HOCKEY,
  handball: SPORT_ID.HANDBALL,
  bandy: SPORT_ID.BANDY,
};

// export const NIFS_SPORT_IDS = {
//   FOOTBALL: 1,
//   ICE_HOCKEY: 2,
//   HANDBALL: 3,
//   BANDY: 4,
// };

// export const SPORT_GROUPS = {
//   FOOTBALL: 'fotball',
//   ICE_HOCKEY: 'ishockey',
//   HANDBALL: 'handball',
//   BANDY: 'bandy',
//   WINTERSPORTS: 'vintersport',
//   ATHLETICS: 'friidrett',
// };

// export const SPORT_GROUP = {
//   [UUIDs.Sport.BIATHLON]: 'vintersport',
//   [UUIDs.Sport.CROSS_COUNTRY_SKIING]: 'vintersport',
//   [UUIDs.Sport.ALPINE]: 'vintersport',
//   [UUIDs.Sport.SKI_JUMPING]: 'vintersport',
//   [UUIDs.Sport.NORDIC_COMBINED]: 'vintersport',
//   [UUIDs.Sport.SPEED_SKATING]: 'vintersport',
//   [UUIDs.Sport.CURLING]: 'vintersport',
//   [UUIDs.Sport.BOBSLEIGH]: 'vintersport',
//   [UUIDs.Sport.LUGE]: 'vintersport',
//   [UUIDs.Sport.SKELETON]: 'vintersport',
//   [UUIDs.Sport.FREESTYLE_SKIING]: 'vintersport',
//   [UUIDs.Sport.SNOWBOARDING]: 'vintersport',
//   [UUIDs.Sport.SHORT_TRACK_SPEED_SKATING]: 'vintersport',
//   [UUIDs.Sport.FIGURE_SKATING]: 'vintersport',
//   [UUIDs.Sport.ATHLETICS]: 'friidrett',
// };

export const CUSTOMER_NAMES = {
  1: 'amedia',
  3: 'tv2',
  4: 'nettavisen',
  5: 'dagbladet',
  6: 'nifs',
  7: 'livesporten',
  13: 'nrk',
};

export const CUSTOMER_CONCERN_IDS = {
  AMEDIA: 1,
  TV2: 3,
  NETTAVISEN: 4,
  DAGBLADET: 5,
  NIFS: 6,
  NRK: 13,
};

export const NEO_CUSTOMER_UUIDS = {
  NRK: '54d017c7-e541-4ac2-a6f3-f53db92d7a74',
};

export const MEDIA_QUERY_SIZE = {
  MOBILE_S: '320px',
  MOBILE_M: '375px',
  MOBILE_L: '412px',
  MOBILE_XL: '540px',
  TABLET_S: '600px',
  TABLET: '720px',
  LAPTOP: '1024px',
  LAPTOP_M: '1280px',
  LAPTOP_L: '1440px',
  DESKTOP: '1620px',
  DESKTOP_L: '1980px',
};

export const NIFS_DISTRICTS_ID_NAME_MAP: { [key: number]: string } = {
  1: 'Norges Fotballforbund',
  2: 'Østfold Fotballkrets',
  3: 'Akershus Fotballkrets',
  4: 'Oslo Fotballkrets',
  5: 'Indre Østland Fotballkrets',
  6: 'Buskerud Fotballkrets',
  7: 'Vestfold Fotballkrets',
  8: 'Telemark Fotballkrets',
  9: 'Rogaland Fotballkrets',
  10: 'Hordaland Fotballkrets',
  11: 'Sogn og Fjordane Fotballkrets',
  12: 'Nordmøre og Romsdal Fotballkrets',
  13: 'Trøndelag Fotballkrets',
  14: 'Nordland Fotballkrets',
  15: 'Troms Fotballkrets',
  16: 'Finnmark Fotballkrets',
  17: 'Sunnmøre Fotballkrets',
  18: 'Hålogaland Fotballkrets',
  19: 'Agder Fotballkrets',
};

export const FAIR_PLAY_SORTING_RULES = [
  { id: 1, norwegianText: 'Poengsnitt' },
  /*   { id: 2, norwegianText: 'Poeng'},
  { id: 2, norwegianText: 'Røde kort'},
  { id: 2, norwegianText: 'Gule kort'}, */
];

export const MEDIA_QUERY = {
  MOBILE_S: `screen and (max-width: ${MEDIA_QUERY_SIZE.MOBILE_S})`,
  MOBILE_M: `(min-width: ${MEDIA_QUERY_SIZE.MOBILE_M})`,
  MOBILE_L: `(min-width: ${MEDIA_QUERY_SIZE.MOBILE_L})`,
  MOBILE_ONLY: `(max-width: ${MEDIA_QUERY_SIZE.TABLET_S})`,
  TABLET_S: `(min-width: ${MEDIA_QUERY_SIZE.TABLET_S})`,
  TABLET: `(min-width: ${MEDIA_QUERY_SIZE.TABLET})`,
  TABLET_ONLY: `only screen and (min-device-width: ${MEDIA_QUERY_SIZE.TABLET}) and (max-device-width: ${MEDIA_QUERY_SIZE.LAPTOP})`,
  TABLET_LANDSCAPE: `(device-width: ${MEDIA_QUERY_SIZE.LAPTOP}) and (device-height: ${MEDIA_QUERY_SIZE.TABLET}) and (orientation: landscape)`,
  LAPTOP: `(min-width: ${MEDIA_QUERY_SIZE.LAPTOP})`,
  LAPTOP_M: `screen and (min-width: ${MEDIA_QUERY_SIZE.LAPTOP_M})`,
  LAPTOP_L: `(min-width: ${MEDIA_QUERY_SIZE.LAPTOP_L})`,
  DESKTOP: `(min-width: ${MEDIA_QUERY_SIZE.DESKTOP})`,
  DESKTOP_L: `(min-width: ${MEDIA_QUERY_SIZE.DESKTOP_L})`,
};

export const DARK_MODE = '@media (prefers-color-scheme: dark)';

export const NIFS_STATISTICS_ACCESSOR_TO_NAME_MAP = {
  assists: 'Målgivende',
  assistsHome: 'Målgivende hjemme',
  assistsAway: 'Målgivende borte',
  assistsAverage: 'Målgivende snitt',
  matches: 'Antall kamper',
  goals: 'Mål',
  goalsHome: 'Mål hjemme',
  goalsAway: 'Mål borte',
  goalsAverage: 'Mål snitt',
  redCards: 'Røde kort',
  yellowCards: 'Gule kort',
};

export enum LineUpPositions {
  GOALKEEPER = 1,
  RIGHT_FULL_BACK = 3,
  RIGHT_CENTER_HALF = 27,
  CENTER_HALF = 5,
  LEFT_CENTER_HALF = 28,
  LEFT_FULL_BACK = 4,

  RIGHT_DEFENSIVE_MIDFIELD = 17,
  RIGHT_DEFENSIVE_CENTRAL_MIDFIELD = 29,
  DEFENSIVE_MIDFIELD = 18,
  LEFT_DEFENSIVE_CENTRAL_MIDFIELD = 30,
  LEFT_DEFENSIVE_MIDFIELD = 19,

  RIGHT_MIDFIELD = 7,
  INSIDE_RIGHT_MIDFIELD = 25,
  RIGHT_CENTRAL_MIDFIELD = 35,
  CENTER_MIDFIELD = 9,
  LEFT_CENTRAL_MIDFIELD = 36,

  INSIDE_LEFT_MIDFIELD = 26,
  LEFT_MIDFIELD = 8,
  RIGHT_OFFENSIVE_MIDFIELD = 22,
  RIGHT_OFFENSIVE_CENTRAL_MIDFIELD = 31,
  OFFENSIVE_MIDFIED = 23,
  
  LEFT_OFFENSIVE_CENTRAL_MIDFIED = 32,
  LEFT_OFFENSIVE_MIDFIED = 24,
  RIGHT_WING = 20,
  RIGHT_STRIKER = 33,
  STRIKER = 10,
  LEFT_STRIKER = 34,
  LEFT_WING = 21,
  FORWARD = 13,
}
