import styled from 'styled-components';

interface StyledProps {
  $highlight?: boolean;
  $isHighlighted?: boolean;
  $isOpen?: boolean;
  $hasQualifier?: boolean;
  $enableToggle?: boolean;
  $hasSecondaryColumn?: boolean;
  $hasTertiaryColumn?: boolean;
}

export const Result = styled.li<StyledProps>`
  background: ${(props) =>
    props?.$isHighlighted &&
    'var(--base-colors-success__light-background-color)'};
  color: var(--font-color);
  transition: background ease-in-out 300ms;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) =>
    props.$isOpen ? 'var(--ntb-space-2) 0 0 0' : 'var(--ntb-space-2) 0'};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-color);
`;
