import styled from 'styled-components';

export const EventHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 26px auto;
  grid-column-gap: var(--ntb-space-4);
  padding: var(--ntb-space-2);
  box-shadow: inset 0 -1px 0 var(--border-color);
  background-color: var(--background-color);
  color: var(--font-color);

  svg {
    fill: currentColor;
  }
`;

export const SportName = styled.div`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
`;
