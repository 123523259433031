import * as S from './Biathlon.styled';
import { NeoScopeResultType } from '@ntb-sport/types';
import ShotSeries from './ShotSeries/ShotSeries';
import { Participant, Rank, Records, Qualified, Duration } from '../Common';

interface BiathlonProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  allShotSeries: any;
  scopeNumber: number;
  isRelay: boolean;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Biathlon = ({
  scopeResult,
  highlightedCountryUUID,
  allShotSeries,
  scopeNumber,
  isRelay,
  isMedalEvent,
  scopeTypeId,
}: BiathlonProps) => {
  return (
    <>
      <S.Left>
        <Rank
          scopeResult={scopeResult}
          highlightedCountryUUID={highlightedCountryUUID}
          isMedalEvent={isMedalEvent}
          scopeTypeId={scopeTypeId}
        />
        <Participant scopeResult={scopeResult} />
      </S.Left>

      <S.Right>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        <ShotSeries
          allShotSeries={allShotSeries}
          isRelay={isRelay}
          scopeNumber={scopeNumber}
          participantUUID={scopeResult?.participant?.uuid}
        />

        <Duration
          duration={scopeResult.duration}
          timeDifference={scopeResult.timeDifference}
          resultId={scopeResult.resultId}
        />
      </S.Right>
    </>
  );
};
