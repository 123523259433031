import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledProps {
  $highlightType?: string;
}

export const Leader = styled.div<StyledProps>`
  grid-area: duration;
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  @container scopeResults ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
