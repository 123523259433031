import styled from 'styled-components';

export const Header = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-column-gap: var(--ntb-space-2);
  align-items: center;
  width: 100%;
  height: 52px;
  padding: var(--ntb-space-2);
  background-color: var(--background-color);
  color: var(--font-color);
  border-bottom-width: 4px;
  border-bottom-color: var(--border-color);
`;

export const Date = styled.span`
  &:first-letter {
    text-transform: capitalize;
  }
`;
