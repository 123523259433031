import * as S from './ShotSeries.styled';

import { NeoShotSeriesType } from '@ntb-sport/types';

interface ShotSeriesProps {
  allShotSeries: NeoShotSeriesType[];
  scopeNumber: number;
  participantUUID?: string;
  isRelay: boolean;
}

interface TotalType {
  hits: number;
  misses: number;
  numberOfShots: number;
  additionalShots: number;
}

const ShotSeries = ({
  allShotSeries,
  scopeNumber,
  participantUUID,
  isRelay,
}: ShotSeriesProps) => {
  const total = allShotSeries
    ?.filter(
      (shotseries: NeoShotSeriesType) =>
        shotseries?.eventParticipantUUID === participantUUID &&
        shotseries?.number <= scopeNumber,
    )
    ?.reduce(
      (total: TotalType, shotseries: NeoShotSeriesType) => {
        total.hits += shotseries?.hits;
        total.misses += shotseries?.misses;
        total.numberOfShots += shotseries?.numberOfShots;

        if (shotseries?.additionalShots) {
          total.additionalShots += shotseries?.additionalShots;
        }

        return total;
      },
      {
        hits: 0,
        misses: 0,
        numberOfShots: 0,
        additionalShots: 0,
      },
    );

  const totalMisses = isRelay
    ? total?.misses - total?.additionalShots
    : total?.misses;

  return (
    <S.ShotSeries $hasAdditionalShots={isRelay}>
      <S.TotalMisses $hasMiss={totalMisses > 0}>
        {totalMisses}
        <S.TotalMissesIcon $hasMiss={totalMisses > 0} />
      </S.TotalMisses>

      {isRelay && (
        <S.TotalAdditionalShots
          $hasAdditionalShots={total?.additionalShots > 0}
        >
          {total?.additionalShots}
          <S.TotalAdditionalShotsIcon
            $hasAdditionalShots={total?.additionalShots > 0}
          />
        </S.TotalAdditionalShots>
      )}
    </S.ShotSeries>
  );
};

export default ShotSeries;
