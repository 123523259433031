import { UUIDs } from 'neo-common-enums';
import { MainEvents } from '../MainEvent/MainEvents';
import * as S from './Competition.styled';
import { BiBroadcast } from 'react-icons/bi';
import { CompetitionType } from '../types';
import { NeoBroadcastType } from '@ntb-sport/types';
import { ELEMENT_IDS } from '@ntb-sport/constants';

interface CompetitionProps {
  competition: CompetitionType;

  onClick?: ({
    sportGroup,
    sportIdTranslated,
    sportId,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
  date: string;
}

export const Competition = ({
  competition,

  onClick,
  date,
}: CompetitionProps) => {
  return (
    <S.Competition
      key={competition?.uuid}
      data-competition-id={competition?.uuid}
      data-element-id={ELEMENT_IDS.COMPETITION}
    >
      <S.CompetitionHeader>
        <S.CompetitionNameAndLocation
          $displayAsColumn={Boolean(competition?.host?.broadcasts)}
        >
          <S.CompetitionName>
            {competition.sport.uuid === UUIDs.Sport.CYCLING
              ? competition?.tour?.name || competition.name
              : competition?.host?.name || competition.name}
          </S.CompetitionName>
          {competition?.sport?.uuid === UUIDs.Sport.ATHLETICS && (
            <S.CompetitionLocation
              $displayDivider={competition?.host?.broadcasts ? false : true}
            >
              {`${
                competition?.events?.[0]?.host?.location?.name ||
                competition?.events?.[0]?.arrangedAtLocations?.[0]?.name
              }, ${
                competition?.events?.[0]?.host?.country?.name ||
                competition?.events?.[0]?.arrangedInCountries?.[0]?.name
              }`}
            </S.CompetitionLocation>
          )}
        </S.CompetitionNameAndLocation>
        {competition?.host?.broadcasts && (
          <S.HostBroadcasts>
            <BiBroadcast
              size="16px"
              color="var(--base-colors-blackAlpha-900)"
              style={{ marginTop: '-3px' }}
            />
            {competition?.host?.broadcasts
              ?.sort((a: NeoBroadcastType, b: NeoBroadcastType) => {
                if (a?.typeId === 2) {
                  return 1;
                } else if (a?.typeId === 1) {
                  return -1;
                }
                return 0;
              })
              ?.map((broadcast: any) => (
                <S.HostBroadcast
                  href={broadcast?.url}
                  target="_blank"
                  rel="noopener"
                  key={broadcast?.uuid}
                >
                  {broadcast?.name}
                </S.HostBroadcast>
              ))}
          </S.HostBroadcasts>
        )}
      </S.CompetitionHeader>
      <MainEvents
        events={competition?.events}
        date={date}
        sport={competition?.sport}
        onClick={onClick}
      />
    </S.Competition>
  );
};
