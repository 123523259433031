import { MEDIA_QUERY } from '@ntb-sport/constants';
import styled from 'styled-components';

export const Distance = styled.div`
  grid-area: length;
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);

  @container scopeResults ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Primary = styled.div``;

export const Secondary = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  color: var(--base-colors-error);
  font-weight: var(--ntb-fontWeights-normal);

  @container scopeResults ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
