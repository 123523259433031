import * as S from './ScopeMenu.styled';

import { LeftArrow, RightArrow } from './Arrows';
import { SporteditScopeType, SporteditScopesType } from '@ntb-sport/types';
import { useDrag, useStateWithDep } from '@ntb-sport/hooks';
import { LabelProperties } from 'neo-common-enums';
import { ScrollMenu as ReactHorizontalScrollMenu } from 'react-horizontal-scrolling-menu';

import { useTranslation } from 'react-i18next';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { Fragment, useState } from 'react';
import { Skeleton, FormControl, FormLabel, Switch } from '../../chakra';

const CLIMB_CATEGORY_NUMBER = {
  'climb-category-hors': 'HC',
  'climb-category-first': 1,
  'climb-category-second': 2,
  'climb-category-third': 3,
  'climb-category-fourth': 4,
};
interface ScopeMenuProps {
  onClick: ({
    scopeId,
    number,
    scopeTypeId,
    scopeCategoryId,
  }: {
    scopeId: string;
    number: number;
    scopeTypeId: number;
    scopeCategoryId?: number;
  }) => void;
  scopeId?: string;
  isLoading?: boolean;
  scopes?: SporteditScopesType;
  eventStatusId: number;
  activeStandingsScope?: SporteditScopeType;
  competitionId?: string;
  sportGroup: string;
}

interface MenuItemProps {
  name: string;
  number: number;
  uuid: string;
  scopeTypeId: number;
  distance?: any;
  scopeCategoryId?: number;
  climbCategoryId?: string;
}

export const ScopeMenu = ({
  onClick,
  scopeId,
  isLoading,
  scopes,
  eventStatusId,
  activeStandingsScope,
  sportGroup,
  competitionId,
}: ScopeMenuProps) => {
  const [selected, setSelected] = useStateWithDep(scopeId);
  const { dragStart, dragStop, dragMove, dragging, touchMove, touchStart } =
    useDrag();
  const { t } = useTranslation();

  const [displayImportantScopes, setDisplayImportantScopes] = useState(
    sportGroup === 'cycling' ? true : false,
  );

  if (
    eventStatusId === LabelProperties.EventStatus.ABANDONED ||
    eventStatusId === LabelProperties.EventStatus.CANCELLED ||
    eventStatusId === LabelProperties.EventStatus.WILL_NOT_BE_HELD
  ) {
    return null;
  }

  const handleDrag =
    ({ scrollContainer }: { scrollContainer: any }) =>
    (ev: any) =>
      dragMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleTouchDrag =
    ({ scrollContainer }: { scrollContainer: any }) =>
    (ev: any) =>
      touchMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleItemClick = ({
    id,
    number,
    scopeTypeId,
    scopeCategoryId,
  }: {
    id: string;
    number: number;
    scopeTypeId: number;
    scopeCategoryId?: number;
    climbCategoryId?: string;
  }) => {
    if (dragging || id === selected) {
      return false;
    }
    onClick({ scopeId: id, number, scopeTypeId, scopeCategoryId });
    setSelected(id);
    return;
  };

  const menuItems = scopes
    ?.filter((scope: SporteditScopeType) =>
      eventStatusId === LabelProperties.EventStatus.FINISHED
        ? scope?.hasScopeResults &&
          scope.scopeTypeId !== 3 &&
          scope.scopeTypeId !== 11000
        : scope.scopeTypeId !== 3 && scope.scopeTypeId !== 11000,
    )

    ?.reduce(
      (menuItems: any, scope: SporteditScopeType) => {
        if (
          !menuItems.find((item: MenuItemProps) => item?.uuid === scope?.uuid)
        ) {
          menuItems.push({
            uuid: scope?.uuid,
            name: scope?.name,
            number: scope?.number,
            distance: scope?.distance,
            scopeTypeId: scope?.scopeTypeId,
            scopeCategoryId: scope?.scopeCategoryId,
            climbCategoryId: scope?.climbCategory?.uuid,
          });
        }

        if (
          !menuItems.find(
            (item: MenuItemProps) => item?.uuid === activeStandingsScope?.uuid,
          ) &&
          activeStandingsScope
        ) {
          menuItems.push({
            uuid: activeStandingsScope?.uuid,
            name: activeStandingsScope?.name,
            distance: activeStandingsScope?.distance,
            scopeTypeId: activeStandingsScope?.scopeTypeId,
            scopeCategoryId: activeStandingsScope?.scopeCategoryId,
            climbCategoryId: scope?.climbCategory?.uuid,
            number: 9999,
          });
        }

        return menuItems;
      },
      [
        {
          uuid: 'eventParticipants',
          name: t('startlist'),
          number: 0,
          scopeTypeId: -1,
        },
      ],
    );

  const centerOnInit = ({
    getItemById,
    scrollToItem,
  }: {
    getItemById: (e: string) => void;
    scrollToItem: any;
  }) => {
    scrollToItem(getItemById(selected), 'auto', 'center');
  };

  const onChangeDisplayImportantScopes = () => {
    setDisplayImportantScopes(!displayImportantScopes);
  };

  return (
    <S.Container
      data-component-id={COMPONENT_IDS.SCROLL_MENU}
      data-competition-id={competitionId}
    >
      {sportGroup === 'cycling' && (
        <FormControl w="auto" display="flex" alignItems="center">
          <FormLabel htmlFor="toggle-highlights" mb={0} fontSize="xs">
            Vis viktige
          </FormLabel>
          <Switch
            id="toggle-highlights"
            size="sm"
            isChecked={displayImportantScopes}
            onChange={onChangeDisplayImportantScopes}
          />
        </FormControl>
      )}
      <S.Wrapper>
        {isLoading ? (
          <Skeleton height={30} width={300} />
        ) : (
          <ReactHorizontalScrollMenu
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            onTouchStart={() => touchStart}
            onTouchEnd={() => dragStop}
            onTouchMove={handleTouchDrag}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            onInit={centerOnInit}
          >
            {menuItems
              ?.filter((item: MenuItemProps) =>
                displayImportantScopes
                  ? Boolean(item.scopeCategoryId) ||
                    item.scopeTypeId === 2 ||
                    item.scopeTypeId === -1
                  : item,
              )
              ?.sort((a: MenuItemProps, b: MenuItemProps) =>
                a?.number < b?.number ? -1 : 0,
              )
              ?.map((item: MenuItemProps) => {
                return (
                  <Fragment key={item?.uuid}>
                    {sportGroup === 'cycling' ? (
                      <S.MenuItemCycling
                        data-element-id={ELEMENT_IDS.BUTTON}
                        key={item?.uuid}
                        itemId={item.uuid}
                        $active={item.uuid === selected}
                        onClick={() =>
                          handleItemClick({
                            id: item?.uuid,
                            number: item?.number,
                            scopeTypeId: item?.scopeTypeId,
                            scopeCategoryId: item?.scopeCategoryId,
                          })
                        }
                      >
                        <S.Name>{item?.name}</S.Name>

                        {/* {item.scopeTypeId !== 2 && item.distance && (
                          <S.Distance>{`${item?.distance?.name / 100}km`}</S.Distance>
                        )} */}
                        {item.scopeCategoryId && (
                          <S.Category $categoryId={item.scopeCategoryId}>
                            {item.scopeCategoryId === 1
                              ? 'S'
                              : item.scopeCategoryId === 2
                                ? CLIMB_CATEGORY_NUMBER[
                                    item.climbCategoryId as keyof typeof CLIMB_CATEGORY_NUMBER
                                  ]
                                : ''}
                          </S.Category>
                        )}
                      </S.MenuItemCycling>
                    ) : (
                      <S.MenuItem
                        data-element-id={ELEMENT_IDS.BUTTON}
                        key={item?.uuid}
                        itemId={item.uuid}
                        $active={item.uuid === selected}
                        onClick={() =>
                          handleItemClick({
                            id: item?.uuid,
                            number: item?.number,
                            scopeTypeId: item?.scopeTypeId,
                            scopeCategoryId: item?.scopeCategoryId,
                            climbCategoryId: item?.climbCategoryId,
                          })
                        }
                      >
                        {item.name}
                      </S.MenuItem>
                    )}
                  </Fragment>
                );
              })}
          </ReactHorizontalScrollMenu>
        )}
      </S.Wrapper>
    </S.Container>
  );
};
