import * as S from './AbbreviationInfo.styled';

import { useTranslation } from 'react-i18next';

import { InfoIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { COMPONENT_IDS, ELEMENT_IDS, SPORT_GROUP } from '@ntb-sport/constants';
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '../../chakra';
import { useDisclosure } from '@ntb-sport/hooks';

export interface AbbreviationInfoProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  sportGroup?: string;
}

export function AbbreviationInfo({
  size = 'xs',
  sportGroup,
}: AbbreviationInfoProps) {
  const { t } = useTranslation();

  const { onOpen, onClose, open } = useDisclosure();

  return (
    <S.Container data-component-id={COMPONENT_IDS.ABBREVIATION_INFO}>
      <Popover
        isOpen={open}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-end"
      >
        <PopoverTrigger>
          <Button
            data-element-id={ELEMENT_IDS.BUTTON}
            size={size}
            bg="var(--background-color)"
            borderRadius="var(--ntb-radii-md)"
            color="var(--font-color)"
            _hover={{
              bg: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              bg: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
            rightIcon={
              open ? (
                <SmallCloseIcon color="currentcolor" />
              ) : (
                <InfoIcon color="currentcolor" />
              )
            }
          >
            {open ? t('close') : t('info')}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody>
            <S.InfoList>
              <S.InfoListDT>()</S.InfoListDT>
              <S.InfoListDD>{t('combinedScore')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                Q
              </S.InfoListDT>
              <S.InfoListDD>{t('qualified.Q')}</S.InfoListDD>
              {sportGroup === SPORT_GROUP.ATHLETICS && (
                <>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    q
                  </S.InfoListDT>
                  <S.InfoListDD>{t('qualified.q')}</S.InfoListDD>
                </>
              )}
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                QA
              </S.InfoListDT>
              <S.InfoListDD>{t('qualified.QA')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                QB
              </S.InfoListDT>
              <S.InfoListDD>{t('qualified.QB')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                LL
              </S.InfoListDT>
              <S.InfoListDD>{t('qualified.LL')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                (LL)
              </S.InfoListDT>
              <S.InfoListDD>{t('qualified.TempLL')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_ERROR}>
                DNS
              </S.InfoListDT>
              <S.InfoListDD>{t('resultId.DNS')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_ERROR}>
                DNF
              </S.InfoListDT>
              <S.InfoListDD>{t('resultId.DNF')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_ERROR}>
                DSQ
              </S.InfoListDT>
              <S.InfoListDD>{t('resultId.DSQ')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_ERROR}>
                LPD
              </S.InfoListDT>
              <S.InfoListDD>{t('resultId.LPD')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_ERROR}>
                NPS
              </S.InfoListDT>
              <S.InfoListDD>{t('resultId.NPS')}</S.InfoListDD>
              <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_ERROR}>
                X
              </S.InfoListDT>
              <S.InfoListDD>{t('resultId.NO_RESULT')}</S.InfoListDD>
              {sportGroup !== SPORT_GROUP.WINTERSPORTS && (
                <>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    =
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.TANGENT')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    WL
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.WL')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    EL
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.EL')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    NL
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.NL')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    PB
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.PB')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    SB
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.SB')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    WR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.WR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    OR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.OR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    CR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.CR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    ER
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.ER')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    NR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.NR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    DLR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.DLR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    AR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.AR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    TR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.TR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    SR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.SR')}</S.InfoListDD>
                  <S.InfoListDT data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
                    MR
                  </S.InfoListDT>
                  <S.InfoListDD>{t('records.MR')}</S.InfoListDD>
                </>
              )}
            </S.InfoList>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </S.Container>
  );
}

export default AbbreviationInfo;
