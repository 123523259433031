import {
  NeoBroadcastType,
  NeoEventType,
  NifsCustomerSettingsType,
} from '@ntb-sport/types';

import { LabelProperties, UUIDs } from 'neo-common-enums';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import * as S from './EventHero.styled';

import { EventHeroVideo } from './EventHeroVideo/EventHeroVideo';
import { EventHeroRadio } from './EventHeroRadio/EventHeroRadio';

import { CUSTOMER_IDS } from '@ntb-sport/customer';
import { NifsCustomerContext } from '@ntb-sport/customer';
import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';
import { EventInfo, Layouts, Variants } from '../EventInfo/EventInfo';

const ROUND_TYPE_TO_NORWEGIAN_NAME = {
  [UUIDs.RoundType.ATHLETICS_FIRST_ROUND]: { order: 1, name: 'Forsøk' },
  [UUIDs.RoundType.PRELIMINARY]: { order: 2, name: 'Innledende' },
  [UUIDs.RoundType.QUALIFICATION]: { order: 3, name: 'Kvalifisering' },
  [UUIDs.RoundType.ROUND_OF_16]: { order: 4, name: '8-delsfinale' },
  [UUIDs.RoundType.QUARTER_FINAL]: { order: 5, name: 'Kvartfinale' },
  [UUIDs.RoundType.PLACEMENT_SEMI_FINAL]: { order: 6, name: '9-12. plass' },
  [UUIDs.RoundType.SEMI_FINAL]: { order: 7, name: 'Semifinale' },
  [UUIDs.RoundType.SEVENTH_PLACE_DECIDER]: { order: 8, name: '7./8. plass' },
  [UUIDs.RoundType.FIFTH_PLACE_DECIDER]: { order: 9, name: '5./6. plass' },
  [UUIDs.RoundType.BRONZE_FINAL]: { order: 10, name: 'Bronsefinale' },
  [UUIDs.RoundType.FINAL]: { order: 11, name: 'Finale' },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_1000_METRES]: {
    order: 1,
    name: '1000 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_100_METERS]: {
    order: 2,
    name: '100 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_100_METRES_HURDLES]: {
    order: 3,
    name: '100 m hekk',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_110_METRES_HURDLES]: {
    order: 4,
    name: '110 m hekk',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_1500_METRES]: {
    order: 5,
    name: '1500 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_200_METRES]: {
    order: 6,
    name: '200 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_400_METRES]: {
    order: 7,
    name: '400 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_60_METRES_HURDLES]: {
    order: 8,
    name: '60 m hekk',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_60_METRES]: {
    order: 9,
    name: '60 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_800_METRES]: {
    order: 10,
    name: '800 m',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_DISCUS_THROW]: {
    order: 11,
    name: 'Diskos',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_HIGH_JUMP]: {
    order: 12,
    name: 'Høydehopp',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_JAVELIN_THROW]: {
    order: 13,
    name: 'Spyd',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_LONG_JUMP]: {
    order: 14,
    name: 'Lengdehopp',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_POLE_VAULT]: {
    order: 15,
    name: 'Stavsprang',
  },
  [UUIDs.RoundType.ATHLETICS_COMBINED_EVENT_SHOT_PUT]: {
    order: 16,
    name: 'Kulestøt',
  },
  [UUIDs.RoundType.FIRST_JUMP]: { order: 1, name: '1. omg' },
  [UUIDs.RoundType.SECOND_JUMP]: { order: 2, name: '2. omg' },
  [UUIDs.RoundType.FIRST_RUN]: { order: 1, name: '1. omg' },
  [UUIDs.RoundType.SECOND_RUN]: { order: 2, name: '2. omg' },
};

function isGroupedByRoundType(event: any) {
  if (
    (event?.labels?.includes('Slalom') &&
      event?.labels?.includes('ParallelStart')) ||
    (event?.labels?.includes('SprintEvent') &&
      event?.seasons?.[0]?.competition?.sport?.uuid ===
        UUIDs.Sport.CROSS_COUNTRY_SKIING) ||
    (event?.seasons?.[0]?.competition?.sport?.uuid === UUIDs.Sport.ATHLETICS &&
      event?.discipline?.uuid !== UUIDs.Discipline.DECATHLON &&
      event?.discipline?.uuid !== UUIDs.Discipline.PENTATHLON &&
      event?.discipline?.uuid !== UUIDs.Discipline.HEPTATHLON)
  ) {
    return true;
  } else {
    return false;
  }
}

function getBroadcasts({ event }: any) {
  const hostBroadcasts = event?.host?.broadcasts;
  const eventBroadcasts = event?.broadcasts;
  const childEvents = event?.childEvents;

  const broadcastsByHost = hostBroadcasts
    ?.filter((broadcast: NeoBroadcastType) => broadcast?.streamId)
    ?.reduce(
      (acc: any, broadcast: any) => {
        const broadcastTypeId =
          broadcast?.typeId === LabelProperties.BroadcastType.STREAM
            ? LabelProperties.BroadcastType.TV
            : broadcast?.typeId;
        if (!acc[broadcastTypeId][broadcast?.streamId as keyof typeof acc]) {
          acc[broadcastTypeId][broadcast?.streamId as keyof typeof acc] =
            broadcast;
        }
        return acc;
      },
      {
        [LabelProperties.BroadcastType.TV]: {},
        [LabelProperties.BroadcastType.RADIO]: {},
      },
    );

  const broadcastsByEvent = eventBroadcasts
    ?.filter((broadcast: NeoBroadcastType) => broadcast?.streamId)
    ?.reduce(
      (acc: any, broadcast: any) => {
        const broadcastTypeId =
          broadcast?.typeId === LabelProperties.BroadcastType.STREAM
            ? LabelProperties.BroadcastType.TV
            : broadcast?.typeId;
        if (!acc[broadcastTypeId][broadcast?.streamId as keyof typeof acc]) {
          acc[broadcastTypeId][broadcast?.streamId as keyof typeof acc] =
            broadcast;
        }
        return acc;
      },
      {
        [LabelProperties.BroadcastType.TV]: {},
        [LabelProperties.BroadcastType.RADIO]: {},
      },
    );

  const broadcastsByRoundType = childEvents?.reduce(
    (acc: any, childEvent: any) => {
      childEvent?.broadcasts
        ?.filter((broadcast: NeoBroadcastType) => broadcast?.streamId)
        ?.forEach((broadcast: NeoBroadcastType) => {
          const broadcastTypeId =
            broadcast?.typeId === LabelProperties.BroadcastType.STREAM
              ? LabelProperties.BroadcastType.TV
              : broadcast?.typeId;
          if (!acc[broadcastTypeId][broadcast?.streamId as keyof typeof acc]) {
            acc[broadcastTypeId][broadcast?.streamId as keyof typeof acc] = {
              roundTypes: [],
              ...broadcast,
            };
          }
          if (
            !acc[broadcastTypeId][
              broadcast?.streamId as keyof typeof acc
            ]?.roundTypes?.find(
              (roundType: any) =>
                roundType?.uuid === childEvent?.roundType?.uuid,
            )
          ) {
            const groupByRoundType = isGroupedByRoundType(event);

            acc[broadcastTypeId][broadcast?.streamId]?.roundTypes.push({
              name: childEvent?.roundType?.name,
              uuid: childEvent?.roundType?.uuid,
              order: groupByRoundType
                ? ROUND_TYPE_TO_NORWEGIAN_NAME[
                    childEvent?.roundType
                      ?.uuid as keyof typeof ROUND_TYPE_TO_NORWEGIAN_NAME
                  ]?.order
                : 1,
              groupName: groupByRoundType
                ? ROUND_TYPE_TO_NORWEGIAN_NAME[
                    childEvent?.roundType
                      ?.uuid as keyof typeof ROUND_TYPE_TO_NORWEGIAN_NAME
                  ]?.name
                : childEvent?.roundType?.name,
            });
          }
        });

      return acc;
    },
    {
      [LabelProperties.BroadcastType.TV]: {},
      [LabelProperties.BroadcastType.RADIO]: {},
    },
  );

  return {
    [LabelProperties.BroadcastType.TV]: {
      ...broadcastsByHost?.[LabelProperties.BroadcastType.TV],
      ...broadcastsByEvent?.[LabelProperties.BroadcastType.TV],
      ...broadcastsByRoundType?.[LabelProperties.BroadcastType.TV],
    },
    [LabelProperties.BroadcastType.RADIO]: {
      ...broadcastsByHost?.[LabelProperties.BroadcastType.RADIO],
      ...broadcastsByEvent?.[LabelProperties.BroadcastType.RADIO],
      ...broadcastsByRoundType?.[LabelProperties.BroadcastType.RADIO],
    },
  };
}

interface EventHeroType {
  event: NeoEventType;
  locale?: string;
  isLoading?: boolean;
  setOffset?: (offset: number) => void;
}

export const EventHero = ({
  event,
  locale = 'nb',
  isLoading,
  setOffset,
}: EventHeroType) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { nifsCustomer } = useContext(NifsCustomerContext) as {
    nifsCustomer: NifsCustomerSettingsType;
  };

  const isNrk = nifsCustomer?.customer?.id === CUSTOMER_IDS.NRK;

  const streams = useMemo(
    () =>
      getBroadcasts({
        event,
      }),
    [event?.host?.broadcasts, event?.broadcasts, event?.childEvents],
  );

  const hasVideoStreams = Boolean(
    Object.keys(streams?.[LabelProperties.BroadcastType.TV])?.length,
  );
  const hasRadioStreams = Boolean(
    Object.keys(streams?.[LabelProperties.BroadcastType.RADIO])?.length,
  );

  const [videoId, setVideoId] = useState<string>();
  const [radioId, setRadioId] = useState<string>();

  useEffect(() => {
    if (containerRef?.current?.offsetHeight) {
      setOffset && setOffset(containerRef?.current?.offsetHeight - 1);
    } else {
      setOffset && setOffset(0);
    }
  }, [containerRef?.current]);

  useEffect(() => {
    Object.keys(streams?.[LabelProperties.BroadcastType.TV])?.length &&
      setVideoId(Object.keys(streams?.[LabelProperties.BroadcastType.TV])?.[0]);
  }, [streams?.[LabelProperties.BroadcastType.TV]]);

  return isLoading ? (
    <Skeleton height={152} />
  ) : (
    <>
      {(!isNrk || !hasVideoStreams) && (
        <EventInfo
          event={event}
          isLoading={false}
          locale={locale}
          variant={Variants.brand}
        />
      )}

      {isNrk && hasVideoStreams && videoId && (
        <S.VideoContainerQuery
          ref={containerRef}
          data-component-id={COMPONENT_IDS.VIDEO_HERO}
          data-competition-id={event?.seasons?.[0]?.competition?.uuid}
        >
          <S.PlayerContainer>
            <EventInfo
              event={event}
              locale={locale}
              layout={Layouts.compact}
              variant={Variants.brand}
              enableToggle={true}
            />

            <EventHeroVideo
              videoId={videoId}
              streams={streams}
              onClick={setVideoId}
            />
          </S.PlayerContainer>
        </S.VideoContainerQuery>
      )}

      {isNrk && hasRadioStreams && (
        <EventHeroRadio
          streams={streams}
          radioId={radioId}
          onClick={setRadioId}
          competitionId={event?.seasons?.[0]?.competition?.uuid}
        />
      )}
    </>
  );
};
