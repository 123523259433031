import { LabelProperties, UUIDs } from 'neo-common-enums';
import { SubEvents } from '../SubEvents/SubEvents';
import {
  ELEMENT_IDS,
  SPORT_ID_BELONGS_TO_SPORT_GROUP,
  SPORT_ID_NORWEGIAN,
} from '@ntb-sport/constants';
import { format, isValid } from 'date-fns';
import { IoMdMedal } from 'react-icons/io';

import * as S from './MainEvent.styled';
import Gender from '../../../common/Gender/Gender';
import { getEventStatus } from '../helpers/getEventStatus';
import { EventType, SportType } from '../types';
import { NeoBroadcastType, NeoEventType } from '@ntb-sport/types';

interface MainEventProps {
  event: EventType;
  onClick?: ({
    sportGroup,
    sportIdTranslated,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
  date: string;
  sport: SportType;
}

export const MainEvent = ({ event, onClick, date, sport }: MainEventProps) => {
  const eventStatus = getEventStatus({
    eventStatusId: event?.eventStatusId,
    timestamp: event?.timestamp,
  });

  const childEventDates = event?.childEvents
    ?.reduce((acc: string[], event: NeoEventType) => {
      if (!acc?.find((item: string) => item === event?.date)) {
        acc.push(event?.date);
      }

      return acc;
    }, [])
    ?.sort((a: string, b: string) => (a < b ? -1 : 0));

  return (
    <S.Event
      data-element-id={ELEMENT_IDS.EVENT}
      onClick={() => {
        onClick &&
          onClick({
            sportGroup:
              SPORT_ID_BELONGS_TO_SPORT_GROUP[
                event?.seasons?.[0]?.competition?.sport?.uuid
              ],
            sportIdTranslated:
              SPORT_ID_NORWEGIAN[event?.seasons?.[0]?.competition?.sport?.uuid],
            sportId: event?.seasons?.[0]?.competition?.sport?.uuid,
            date:
              isValid(new Date(date)) && format(new Date(date), 'yyyy-MM-dd'),
            uuid: event?.uuid,
          });
      }}
    >
      <S.StatusContainer>
        {(event?.eventStatusId === LabelProperties.EventStatus.CANCELLED ||
          event?.labels?.includes('NonParentEvent')) && (
          <S.Status $type={eventStatus?.type}>
            {eventStatus?.type === 'default' && eventStatus?.text}
          </S.Status>
        )}
      </S.StatusContainer>

      <S.Broadcast>
        {event?.broadcasts
          ?.filter(
            (broadcast: NeoBroadcastType) =>
              broadcast?.typeId === LabelProperties.BroadcastType.TV,
          )
          ?.map((broadcast: NeoBroadcastType) => (
            <S.BroadcastChannel key={broadcast?.broadcastingChannel?.uuid}>
              {broadcast?.broadcastingChannel?.name}
            </S.BroadcastChannel>
          ))}
      </S.Broadcast>

      <S.EventInfo>
        <S.EventNameAndGender>
          <S.EventName>{event?.name}</S.EventName>

          {event?.labels?.includes('NonParentEvent') &&
            eventStatus?.type !== 'default' && (
              <S.EventLocation>
                {' - '}
                <S.StatusInfo $type={eventStatus?.type}>
                  {eventStatus?.text}
                </S.StatusInfo>
              </S.EventLocation>
            )}
          {childEventDates?.length > 1 && (
            <S.EventDays>
              {`(dag `}
              {childEventDates?.findIndex((d: string) => d === date) + 1}
              {' av '}
              {childEventDates?.length}
              {')'}
            </S.EventDays>
          )}

          <Gender gender={event?.gender?.uuid} />
          {event?.hasAthletesFromCountry && (
            <S.HasAthletesFromCountry countryCode="no" />
          )}
          {event?.isMedalEvent && <IoMdMedal />}
        </S.EventNameAndGender>

        {(event?.arrangedAtLocations?.[0]?.name ||
          event?.arrangedInCountries?.[0]?.name) &&
          sport?.uuid !== UUIDs.Sport.ATHLETICS && (
            <S.EventLocation>
              {`${
                event?.arrangedAtLocations?.[0]?.name
                  ? event?.arrangedAtLocations?.[0]?.name + ', '
                  : ''
              } ${event?.arrangedInCountries?.[0]?.name || ''}`}

              {(event?.eventStatusId ===
                LabelProperties.EventStatus.CANCELLED ||
                event?.labels?.includes('NonParentEvent')) &&
                eventStatus?.type !== 'default' && (
                  <>
                    {' - '}
                    <S.StatusInfo $type={eventStatus?.type}>
                      {eventStatus?.text}
                    </S.StatusInfo>
                  </>
                )}
            </S.EventLocation>
          )}
      </S.EventInfo>

      {event?.eventStatusId !== LabelProperties.EventStatus.CANCELLED &&
        (event?.childEventsGroupedByRoundType || event?.childEvents) && (
          <SubEvents events={event?.childEventsGroupedByRoundType} />
        )}
    </S.Event>
  );
};
