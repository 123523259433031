import { RESULT_IDS } from '@ntb-sport/constants';
import * as S from './ResultId.styled';

interface ResultIdType {
  resultId: number;
}

const ResultId = ({ resultId }: ResultIdType) => {
  if (!RESULT_IDS[resultId as keyof typeof RESULT_IDS]) return null;

  return (
    <S.ResultId
      $highlightType={RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.type}
    >
      {RESULT_IDS[resultId as keyof typeof RESULT_IDS]?.name}
    </S.ResultId>
  );
};

export default ResultId;
