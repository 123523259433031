import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

export const Records = styled.div`
  grid-area: records;
  display: flex;
  column-gap: var(--ntb-space-0-5);
  align-items: center;
  justify-self: end;
`;

export const Record = styled.div`
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  @container scopeResults ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
