import { Box, Button } from '../../../../chakra';
import { ELEMENT_IDS, SPORT_ID_TO_NORWEGIAN_NAME } from '@ntb-sport/constants';
import { format } from 'date-fns/format';
import * as S from './Days.styled';
import { DateObj } from 'dayzed';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

interface DaysProps {
  weeks: Array<Array<DateObj | ''>>;
  month: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  year: number;
  daysWithEventsInMonth: any;
  isLoadingDaysWithEventsInMonth?: boolean;
  getDateProps: any;
  isOpen: boolean;
}

export const Days = ({
  weeks,
  month,
  year,
  daysWithEventsInMonth,
  isLoadingDaysWithEventsInMonth,
  getDateProps,
  isOpen,
}: DaysProps) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        justifyItems: 'center',
        gridColumnGap: 'var(--ntb-space-2)',
        gridRowGap: ' var(--ntb-space-2)',
      }}
    >
      {weeks.map((week: any, weekIndex: number) =>
        week.map((dateObj: DateObj, index: number) => {
          const key = `${month}${year}${weekIndex}${index}`;
          if (!dateObj) {
            return <div key={key} />;
          }
          const { date, selected }: { date: Date; selected: boolean } = dateObj;

          const dayInfo = daysWithEventsInMonth?.find(
            (d: any) => d?.date === format(date, 'yyyy-MM-dd'),
          );
          const sports = dayInfo?.sports
            ?.reduce((acc: string[], item: { uuid: string }) => {
              acc.push(SPORT_ID_TO_NORWEGIAN_NAME[item?.uuid]);
              return acc;
            }, [])
            ?.toString();

          const isValidDate = daysWithEventsInMonth ? Boolean(dayInfo) : true;
          const focusRef = useRef<HTMLButtonElement>(null);

          useEffect(() => {
            focusRef?.current?.focus();
          }, [isOpen]);

          return (
            <Button
              {...getDateProps({ dateObj })}
              data-element-id={ELEMENT_IDS.BUTTON}
              isActive={selected}
              ref={selected ? focusRef : null}
              tabIndex={selected ? -1 : 0}
              borderWidth={isValidDate && '1px'}
              borderColor="var(--border-color)"
              backgroundColor={
                isValidDate ? 'var(--background-color)' : 'transparent'
              }
              color="var(--font-color)"
              _hover={{
                bg: 'var(--background-color__hover)',
                borderColor: 'var(--border-color__hover)',
                color: 'var(--font-color__hover)',
              }}
              _active={{
                bg: 'var(--background-color__active)',
                borderColor: 'var(--border-color__active)',
                color: 'var(--font-color__active)',
              }}
              size="sm"
              isLoading={isLoadingDaysWithEventsInMonth}
              isDisabled={!isValidDate}
              w="100%"
              key={key}
              aria-label={` ${new Date(date).toLocaleDateString('nb-NO', { day: '2-digit', month: 'long' })}`}
            >
              {date.getDate()}
            </Button>
          );
        }),
      )}
    </div>
  );
};
