import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '@ntb-sport/constants';
import { fetchEvent, FetchEventProps } from './fetch';
import { getIsNeoSportGroup } from '@ntb-sport/helpers';
import { useSportEditSocketHandleRooms } from '@ntb-sport/socket';
import { SPORT_GROUP } from '@ntb-sport/constants';
import { API_VERSION } from '../../fetch';

interface UseQueryEventParams extends FetchEventProps {
  sportGroup: string;
}

interface UseQueryEvent {
  queryKey?: string;
  queryKeyParams: Record<string, unknown>;
  params: UseQueryEventParams;
  options?: Record<string, unknown>;
  enableSocket?: boolean;
}

export const useQueryEvent = ({
  queryKey = QUERY_KEYS.EVENT,
  queryKeyParams,
  params,
  options,
  enableSocket = false,
}: UseQueryEvent) => {
  const isSportEditSportGroup = getIsNeoSportGroup(params?.sportGroup);

  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: [queryKey, queryKeyParams],
    queryFn: () => fetchEvent(params),
    enabled:
      isSportEditSportGroup &&
      Boolean(params.customerUUID) &&
      Boolean(params.token) &&
      Boolean(params.eventUUID),
    ...options,
  });

  useSportEditSocketHandleRooms({
    enabled:
      enableSocket &&
      Boolean(params.eventUUID) &&
      params.sportGroup === SPORT_GROUP.CYCLING,
    roomId: `/${params?.version || API_VERSION.V3}/events/${params.eventUUID}`,
  });

  return { data, isLoading, error };
};
