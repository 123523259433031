import * as S from './Cycling.styled';
import { NeoScopeResultType } from '@ntb-sport/types';

import {
  Participant,
  Rank,
  Records,
  Qualified,
  Duration,
  Points,
} from '../Common';
import { Jerseys } from '../Common/Jerseys/Jerseys';
import Leader from '../Common/Leader/Leader';
import ResultId from '../Common/ResultId/ResultId';

interface CyclingProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  tourCompetitionUuid?: string;
  scopeCategoryId?: number;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Cycling = ({
  scopeResult,
  highlightedCountryUUID,
  tourCompetitionUuid,
  scopeCategoryId,
  isMedalEvent,
  scopeTypeId,
}: CyclingProps) => {
  return (
    <>
      <S.Left>
        {scopeResult?.rank && (
          <Rank
            scopeResult={scopeResult}
            highlightedCountryUUID={highlightedCountryUUID}
            isMedalEvent={isMedalEvent}
            scopeTypeId={scopeTypeId}
          />
        )}
        <Participant scopeResult={scopeResult} />
        <Jerseys
          scopeResult={scopeResult}
          tourCompetitionUuid={tourCompetitionUuid}
        />
      </S.Left>

      <S.Right>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        {scopeCategoryId ? (
          <Points points={scopeResult.points} resultId={scopeResult.resultId} />
        ) : Boolean(scopeResult.duration) ||
          Boolean(scopeResult.timeDifference) ? (
          <Duration
            duration={scopeResult.duration}
            timeDifference={scopeResult.timeDifference}
            resultId={scopeResult.resultId}
          />
        ) : (
          <>
            {scopeResult?.resultId ? (
              <ResultId resultId={scopeResult.resultId} />
            ) : (
              <Leader />
            )}
          </>
        )}
      </S.Right>
    </>
  );
};
