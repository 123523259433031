import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ntb-space-2);
  background-color: var(--background-color);
  color: var(--font-color);
`;

export const Title = styled.h2`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const AbbreviationInfoContainer = styled.div`
  margin-left: auto;
`;
