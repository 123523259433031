import { UUIDs } from 'neo-common-enums';
import { Competition } from '../Competition/Competition';
import * as S from './Sport.styled';

import {
  BiathlonSportIcon,
  AlpineSportIcon,
  CrossCountrySkiingSportIcon,
  NordicCombinedSportIcon,
  SpeedSkatingSportIcon,
  SkiJumpingSportIcon,
  AthleticsIcon,
  CyclingSportIcon,
} from '../../../icons';
import { SportType } from '../types';
import { ELEMENT_IDS } from '@ntb-sport/constants';

interface SportProps {
  sport: SportType;
  date: string;
  onClick?: ({
    sportGroup,
    sportIdTranslated,
    sportId,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
}

export const Sport = ({ sport, date, onClick }: SportProps) => {
  return (
    <>
      <S.EventHeader data-element-id={ELEMENT_IDS.SPORT}>
        {sport?.uuid === UUIDs.Sport.ALPINE && <AlpineSportIcon />}
        {sport?.uuid === UUIDs.Sport.BIATHLON && <BiathlonSportIcon />}
        {sport?.uuid === UUIDs.Sport.CROSS_COUNTRY_SKIING && (
          <CrossCountrySkiingSportIcon />
        )}
        {sport?.uuid === UUIDs.Sport.NORDIC_COMBINED && (
          <NordicCombinedSportIcon />
        )}
        {sport?.uuid === UUIDs.Sport.SPEED_SKATING && <SpeedSkatingSportIcon />}
        {sport?.uuid === UUIDs.Sport.SKI_JUMPING && <SkiJumpingSportIcon />}
        {sport?.uuid === UUIDs.Sport.ATHLETICS && <AthleticsIcon />}
        {sport?.uuid === UUIDs.Sport.CYCLING && <CyclingSportIcon />}
        <S.SportName>{sport?.name}</S.SportName>
      </S.EventHeader>
      {Object.values(sport?.competitions)?.map((competition: any) => (
        <Competition
          key={competition?.uuid}
          competition={competition}
          date={date}
          onClick={onClick}
        />
      ))}
    </>
  );
};
