import { Result } from 'libs/ui/src/example/ExampleComponentTwo/ExampleComponentTwo.styled';
import * as S from './Distance.styled';
import ResultId from '../ResultId/ResultId';

interface DistanceProps {
  distance: string;
  resultId: number;
}

export const Distance = ({ distance, resultId }: DistanceProps) => {
  return (
    <S.Distance>
      <ResultId resultId={resultId} />
      {distance && `${distance}m`}
    </S.Distance>
  );
};
