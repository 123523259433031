import { UUIDs } from 'neo-common-enums';
import { Cycling } from './Cycling/Cycling';
import { SkiJumping } from './SkiJumping/SkiJumping';
import { Biathlon } from './Biathlon/Biathlon';
import { NeoScopeResultType } from '@ntb-sport/types';
import { Default, DefaultTypes } from './Default/Default';
import * as Utils from 'neo-common-utils';
import * as S from './Result.styled';
import { ELEMENT_IDS } from '@ntb-sport/constants';

interface ResultProps {
  scopeResult: NeoScopeResultType;
  sportUUID: string;
  highlightedCountryUUID?: string;
  allShotSeries: any;
  scopeNumber: number;
  isRelay: boolean;
  labels?: string[];
  disciplineUUID?: string;
  tourCompetitionUuid?: string;
  scopeCategoryId?: number;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

const getComponent = ({
  scopeResult,
  highlightedCountryUUID,
  sportUUID,
  labels,
  disciplineUUID,
  scopeNumber,
  allShotSeries,
  isRelay,
  tourCompetitionUuid,
  scopeCategoryId,
  isMedalEvent,
  scopeTypeId,
}: ResultProps) => {
  if (
    sportUUID === UUIDs.Sport.SKI_JUMPING ||
    labels?.includes('SkiJumping') ||
    labels?.includes('NormalHill') ||
    labels?.includes('LargeHill') ||
    labels?.includes('SkiFlying')
  ) {
    return (
      <SkiJumping
        scopeResult={scopeResult}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
    );
  } else if (sportUUID === UUIDs.Sport.CYCLING) {
    return (
      <Cycling
        scopeResult={scopeResult}
        highlightedCountryUUID={highlightedCountryUUID}
        tourCompetitionUuid={tourCompetitionUuid}
        scopeCategoryId={scopeCategoryId}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
    );
  } else if (sportUUID === UUIDs.Sport.BIATHLON) {
    return (
      <Biathlon
        scopeNumber={scopeNumber}
        scopeResult={scopeResult}
        highlightedCountryUUID={highlightedCountryUUID}
        allShotSeries={allShotSeries}
        isRelay={isRelay}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
    );
  } else if (disciplineUUID === UUIDs.Discipline.SPEED_SKATING_MASS_START) {
    <Default
      scopeResult={scopeResult}
      type={DefaultTypes.POINTS}
      highlightedCountryUUID={highlightedCountryUUID}
      isMedalEvent={isMedalEvent}
      scopeTypeId={scopeTypeId}
    />;
  } else if (
    disciplineUUID === UUIDs.Discipline.HALF_PIPE ||
    sportUUID === UUIDs.Sport.FREESTYLE_SKIING
  ) {
    return (
      <Default
        scopeResult={scopeResult}
        type={DefaultTypes.POINTS}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
    );
  } else if (
    (disciplineUUID &&
      Utils?.SportDiscipline?.isAthleticThrowingDiscipline(disciplineUUID)) ||
    disciplineUUID === UUIDs.Discipline.DECATHLON_HIGH_JUMP ||
    disciplineUUID === UUIDs.Discipline.DECATHLON_LONG_JUMP ||
    disciplineUUID === UUIDs.Discipline.DECATHLON_POLE_VAULT ||
    disciplineUUID === UUIDs.Discipline.HEPTATHLON_HIGH_JUMP ||
    disciplineUUID === UUIDs.Discipline.HEPTATHLON_LONG_JUMP ||
    disciplineUUID === UUIDs.Discipline.HEPTATHLON_POLE_VAULT ||
    disciplineUUID === UUIDs.Discipline.PENTATHLON_HIGH_JUMP ||
    disciplineUUID === UUIDs.Discipline.PENTATHLON_LONG_JUMP ||
    disciplineUUID === UUIDs.Discipline.HIGH_JUMP ||
    disciplineUUID === UUIDs.Discipline.LONG_JUMP ||
    disciplineUUID === UUIDs.Discipline.POLE_VAULT ||
    disciplineUUID === UUIDs.Discipline.TRIPLE_JUMP
  ) {
    return (
      <Default
        scopeResult={scopeResult}
        type={DefaultTypes.DISTANCE}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
    );
  } else {
    return (
      <Default
        scopeResult={scopeResult}
        type={DefaultTypes.DURATION}
        highlightedCountryUUID={highlightedCountryUUID}
        isMedalEvent={isMedalEvent}
        scopeTypeId={scopeTypeId}
      />
    );
  }
};

export const Result = (props: ResultProps) => {
  const component = getComponent({ ...props });

  return (
    <S.Result data-element-id={ELEMENT_IDS.LIST_ITEM}>{component}</S.Result>
  );
};
