import { ELEMENT_IDS } from '@ntb-sport/constants';
import AbbreviationInfo from '../../../sportedit/AbbreviationInfo/AbbreviationInfo';
import * as S from './Header.styled';

interface HeaderProps {
  sportGroup: string | undefined;
  scopeCategoryId?: number;
}

const SCOPE_CATEGORY_ID_TITLE = {
  1: '(Spurtpoeng)',
  2: '(Klatrepoeng)',
};

export const Header = ({ sportGroup, scopeCategoryId }: HeaderProps) => {
  const title = scopeCategoryId
    ? `Resultater ${SCOPE_CATEGORY_ID_TITLE[scopeCategoryId as keyof typeof SCOPE_CATEGORY_ID_TITLE]}`
    : 'Resultater';

  return (
    <S.Header data-element-id={ELEMENT_IDS.HEADER}>
      <S.Title>{title}</S.Title>

      {sportGroup !== 'cycling' && (
        <S.AbbreviationInfoContainer>
          <AbbreviationInfo sportGroup={sportGroup} />
        </S.AbbreviationInfoContainer>
      )}
    </S.Header>
  );
};
