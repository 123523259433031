import styled, { css } from 'styled-components';

interface StyledProps {
  $displayDivider?: boolean;
  $displayAsColumn?: boolean;
}

export const Competition = styled.div``;

export const CompetitionHeader = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);
  box-shadow: inset 0 -1px 0 var(--border-color);
  padding: var(--ntb-space-2);
  background: var(--background-color);
  display: flex;
  justify-content: space-between;
`;

export const CompetitionNameAndLocation = styled.div<StyledProps>`
  display: flex;
  align-items: ${(props) => (props.$displayAsColumn ? 'flex-start' : 'center')};
  flex-direction: ${(props) => (props.$displayAsColumn ? 'column' : 'row')};
`;
export const CompetitionName = styled.h3`
  &::first-letter {
    text-transform: capitalize;
  }
`;
export const CompetitionLocation = styled.span<StyledProps>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  ${(props) =>
    props?.$displayDivider &&
    css`
      &:before {
        content: '-';
        padding: 0 var(--ntb-space-1);
      }
    `};
`;

export const HostBroadcasts = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;
export const HostBroadcast = styled.a`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);
  &:hover {
    color: var(--base-button-background-color__hover);
  }
  &:not(:last-child) {
    &:after {
      content: '-';
      padding-left: var(--ntb-space-1);
    }
  }
`;

export const CompetitionLogo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: var(--ntb-radii-md);
`;
