import { useContext, useEffect, useState } from 'react';

import { ArrowIcon } from '../../icons';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import styled from 'styled-components';

import { ELEMENT_IDS } from '@ntb-sport/constants';
import IconButton from '../../chakra/IconButton/IconButton';

const PrevArrowIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
`;

const NextArrowIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;

export const LeftArrow = () => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <IconButton
      data-element-id={ELEMENT_IDS.BUTTON}
      disabled={disabled}
      size="xs"
      aria-label=""
      icon={<PrevArrowIcon />}
      onClick={() => scrollPrev()}
      mr={2}
      backgroundColor="var(--background-color)"
      color="var(--font-color)"
      _hover={{
        backgroundColor: 'var(--background-color__hover)',
        color: 'var(--font-color__hover)',
      }}
      _disabled={{
        opacity: 0,
      }}
    />
  );
};

export const RightArrow = () => {
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    useContext(VisibilityContext);

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <IconButton
      data-element-id={ELEMENT_IDS.BUTTON}
      disabled={disabled}
      size="xs"
      aria-label=""
      icon={<NextArrowIcon />}
      onClick={() => scrollNext()}
      ml={2}
      backgroundColor="var(--background-color)"
      color="var(--font-color)"
      _hover={{
        backgroundColor: 'var(--background-color__hover)',
        color: 'var(--font-color__hover)',
      }}
      _disabled={{
        opacity: 0,
      }}
    />
  );
};
