import { Result } from 'libs/ui/src/example/ExampleComponentTwo/ExampleComponentTwo.styled';
import * as S from './DefaultDistance.styled';
import ResultId from '../ResultId/ResultId';

interface DistanceProps {
  distance: string;
  resultId: number;
}

export const DefaultDistance = ({ distance, resultId }: DistanceProps) => {
  return (
    <S.DefaultDistance>
      <ResultId resultId={resultId} />
      {distance && `${distance}m`}
    </S.DefaultDistance>
  );
};
