import { AimIcon, BulletIcon } from '../../../../../icons';
import styled, { css } from 'styled-components';

interface StyledProps {
  $hasMiss?: boolean;
  $hasAdditionalShots?: boolean;
}

export const ShotSeries = styled.div<StyledProps>`
  grid-area: shotSeries;
  display: flex;
  align-items: center;
  justify-self: end;

  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);

  ${(props) =>
    props.$hasAdditionalShots &&
    css`
      display: grid;
      justify-items: end;
      grid-template-columns: max-content max-content;
      column-gap: var(--ntb-space-2);
    `}
`;

export const TotalMisses = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  color: ${(props) =>
    props.$hasMiss ? 'var(--base-colors-error)' : 'var(--base-colors-success)'};
`;

export const TotalAdditionalShots = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
  color: ${(props) =>
    props.$hasAdditionalShots
      ? 'var(--base-colors-error)'
      : 'var(--base-colors-success)'};
`;

export const TotalMissesIcon = styled(AimIcon)<StyledProps>`
  width: 16px;
  height: 16px;
  stroke-width: 3;
  stroke: ${(props) =>
    props.$hasMiss ? 'var(--base-colors-error)' : 'var(--base-colors-success)'};
  fill: transparent;
`;

export const TotalAdditionalShotsIcon = styled(BulletIcon)<StyledProps>`
  width: 16px;
  height: 16px;
  stroke-width: 3;
  stroke: ${(props) =>
    props.$hasAdditionalShots
      ? 'var(--base-colors-error)'
      : 'var(--base-colors-success)'};
  fill: transparent;
`;
