import { createContext } from 'react';
import { NifsSocket } from './NifsSocket';
import { NeoSocket } from './NeoSocket';
import { SportEditSocket } from './SportEditSocket';

interface SocketProviderProps {
  sockets: {
    nifsSocket?: NifsSocket;
    neoSocket?: NeoSocket;
    sportEditSocket?: SportEditSocket;
  };
  children: React.ReactNode;
}

export const SocketContext = createContext({});

export const SocketProvider = ({ sockets, children }: SocketProviderProps) => {
  return (
    <SocketContext.Provider value={sockets}>{children}</SocketContext.Provider>
  );
};
