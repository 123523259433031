import { Grid, GridItem } from '../../chakra';
import * as S from './Menu.styled';

import { MatchesType } from '@ntb-sport/types';

interface MenuProps {
  items: {
    name: string;
    icon?: any;
  }[];
  matches: MatchesType;
  isFixed?: boolean;
  justifyItems?: 'start' | 'center' | 'end';
  children?: any;
}

export const Menu = ({
  items,
  matches,
  isFixed,
  justifyItems = 'center',
  children,
}: MenuProps) => {
  return (
    <S.Menu
      $isFixed={isFixed}
      $matches={matches}
      $justifyItems={justifyItems}
      $hasChildren={Boolean(children)}
    >
      {children}
      <Grid
        templateColumns={`repeat(${items?.length}, 1fr)`}
        justifyItems="center"
        width={matches?.desktopS ? 'auto' : '100%'}
      >
        {items?.map((route) => (
          <GridItem key={route?.name} w="100%">
            <S.MenuItem $matches={matches}>
              {route?.icon}
              {route?.name}
            </S.MenuItem>
          </GridItem>
        ))}
      </Grid>
    </S.Menu>
  );
};

export default Menu;
