import { Props as DayzedProps, useDayzed } from 'dayzed';
import { Days } from './Days/Days';
import { MonthPicker } from './MonthPicker/MonthPicker';
import { Weekdays } from './Weekdays/Weekdays';
import { ELEMENT_IDS } from '@ntb-sport/constants';
import { Flex, Button } from '../../../chakra';
import { RefObject, useRef } from 'react';

interface CalendarProps extends DayzedProps {
  daysWithEventsInMonth?: any;
  isOpen: boolean;
  translations: {
    previousDay: string;
    nextDay: string;
    previousMonth: string;
    nextMonth: string;
    months: string[];
    weekdays: string[];
    today: string;
  };

  setIsOpen: (isOpen: boolean) => void;
  isLoadingDaysWithEventsInMonth?: boolean;
  onClickToday: () => void;
  toggleDatepickerRef: RefObject<HTMLButtonElement>;
}

export const Calendar = ({
  isOpen,
  setIsOpen,
  daysWithEventsInMonth,
  onClickToday,
  translations,
  isLoadingDaysWithEventsInMonth,
  toggleDatepickerRef,
  ...rest
}: CalendarProps) => {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    ...rest,
  });

  let calendarRef = useRef<HTMLDivElement>(null);
  let monthRef = useRef<HTMLDivElement>(null);

  const handleFocusOut = (event: any) => {
    if (
      calendarRef?.current &&
      !calendarRef?.current?.contains(event?.relatedTarget) &&
      toggleDatepickerRef?.current &&
      !toggleDatepickerRef?.current?.contains(event?.relatedTarget)
    ) {
      setIsOpen(false);
    }
  };

  return calendars?.map((calendar) => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '52px',
          left: '0',
          width: '100%',
          height: '100vh',
          backgroundColor: 'var(--background-color)',
          color: 'var(--font-color)',
          zIndex: 99,
          padding: 'var(--ntb-space-2)',
        }}
        ref={calendarRef}
        tabIndex={0}
        aria-modal="true"
        id="calendar"
        aria-labelledby="keyboard-instructions"
        aria-description="Kalder for valg av dato"
        data-element-id={ELEMENT_IDS.CONTAINER}
        hidden={!isOpen}
        onBlur={handleFocusOut}
        aria-live="polite"
        key={`${calendar.month}${calendar.year}`}
        onKeyDown={(e) => {
          if (e.altKey && e.code === 'KeyM') {
            e.preventDefault();
            monthRef?.current?.focus();
          }
        }}
      >
        <MonthPicker
          monthRef={monthRef}
          calendars={calendars}
          month={calendar.month}
          year={calendar.year}
          getBackProps={getBackProps}
          getForwardProps={getForwardProps}
          translations={translations}
        />
        <Weekdays
          weekdays={translations?.weekdays}
          year={calendar.year}
          month={calendar.month}
        />
        <Days
          isOpen={isOpen}
          weeks={calendar.weeks}
          year={calendar.year}
          month={calendar.month}
          daysWithEventsInMonth={daysWithEventsInMonth}
          isLoadingDaysWithEventsInMonth={isLoadingDaysWithEventsInMonth}
          getDateProps={getDateProps}
        />
        <Flex p={4} justifyContent="center">
          <Button
            data-element-id={ELEMENT_IDS.BUTTON}
            size="sm"
            onClick={onClickToday}
            borderWidth="1px"
            backgroundColor="var(--background-color)"
            borderColor="var(--border-color)"
            color="var(--font-color)"
            _hover={{
              backgroundColor: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              backgroundColor: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
          >
            {translations?.today}
          </Button>
        </Flex>
        <div id="keyboard-instructions" className="visually-hidden">
          Du er i en kalender. Valg av dato vil lukke kalenderen og oppdatere
          kamplisten. Bruk følgende kommando for å gå til valg av måned: Alt + M
        </div>
      </div>
    );
  });
};
