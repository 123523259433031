import styled, { css } from 'styled-components';

// import CountryFlag from '../CountryFlag/CountryFlag';
import { MatchesType } from '@ntb-sport/types';
import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledType {
  $displayInline?: boolean;
}

export const Participant = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const Container = styled.div<StyledType>`
  display: flex;
  flex-direction: ${(props) => (props?.$displayInline ? 'row' : 'column')};
  align-items: ${(props) => (props?.$displayInline ? 'center' : 'flex-start')};
`;

export const Names = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: var(--ntb-space-1);
`;

export const Name = styled.div`
  display: flex;
`;

export const BibNumber = styled.div<StyledType>`
  font-size: var(--ntb-fontSizes-xs);
  color: var(--font-color);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const PrimaryName = styled.div<StyledType>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  &::first-letter {
    text-transform: capitalize;
  }

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const SecondaryName = styled.div<StyledType>`
  font-size: var(--ntb-fontSizes-xs);
  color: var(--font-color);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const TertiaryName = styled.div<StyledType>`
  display: flex;
  flex-direction: column;
  column-gap: var(--ntb-space-1);
  color: var(--font-color);
  font-size: var(--ntb-fontSizes-xs);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-xs);
    flex-direction: row;
  }

  ${(props) =>
    props.$displayInline &&
    css`
      margin-left: 3px;
      &:before {
        content: '(';
      }
      &:after {
        content: ')';
      }
    `}
`;

export const Lineup = styled.div<StyledType>`
  color: var(--font-color);
  font-weight: var(--ntb-fontWeights-light);

  font-size: var(--ntb-fontSizes-xs);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-xs);
  }
`;

export const Exchanger = styled.div<StyledType>`
  color: var(--font-color);
  font-weight: var(--ntb-fontWeights-light);

  font-size: var(--ntb-fontSizes-xs);

  @container container ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-xs);
  }
`;
