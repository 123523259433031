import styled, { css } from 'styled-components';

import CountryFlag from '../../../common/CountryFlag/CountryFlag';

interface StyledProps {
  $type?: any;
}

export const SubEventGroup = styled.div`
  display: grid;
  grid-template-areas: 'status name broadcast';
  grid-template-columns: 34px auto;
  grid-column-gap: var(--ntb-space-4);
  align-items: center;
  color: var(--base-colors-blackAlpha-700);
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const SubEventGroupStatus = styled.div<StyledProps>`
  grid-area: status;
  justify-self: center;
  display: flex;
  align-items: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);
  color: var(--base-colors-blackAlpha-700);

  ${(props) =>
    props.$type === 'success' &&
    css`
      color: var(--base-colors-success);
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: var(--base-colors-success);
        border-radius: 50%;
      }
    `}

  ${(props) =>
    props.$type === 'error' &&
    css`
      color: var(--base-colors-error);
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: var(--base-colors-error);
        border-radius: 50%;
      }
    `}
`;

export const SubEventGroupContainer = styled.div`
  grid-area: name;
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-1);
`;

export const ChildEventName = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  color: var(--base-colors-blackAlpha-700);
  font-weight: var(--ntb-fontWeights-medium);

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const ChildEventHasAthletesFromCountry = styled(CountryFlag).withConfig({
  shouldForwardProp: (prop) => ['countryCode'].includes(prop),
})`
  min-width: auto;
  max-height: 12px;
`;

export const Broadcast = styled.div`
  grid-area: broadcast;
  display: flex;
  flex-direction: column;
  column-gap: var(--ntb-space-2);
  row-gap: var(--ntb-space-2);
`;

export const BroadcastChannel = styled.div`
  font-size: var(--ntb-fontSizes-xs);
  text-align: right;
  color: var(--base-colors-blackAlpha-700);
  font-weight: var(--ntb-fontWeights-light);
`;

export const StatusInfo = styled.span<StyledProps>`
  color: ${(props) =>
    props?.$type === 'error'
      ? 'var(--base-colors-error)'
      : 'var(--base-colors-success)'};
`;
