import * as S from './StylePoints.styled';

import {
  SporteditStylePointsType,
  SporteditStylePointType,
} from '@ntb-sport/types';

export const StylePoints = ({
  stylePoints,
}: {
  stylePoints: SporteditStylePointsType;
}) => {
  const highestJudgePoint =
    stylePoints &&
    Math.max(
      ...stylePoints.map((stylePoint: SporteditStylePointType) =>
        Number(stylePoint.points),
      ),
    );

  const lowestJudgePoint =
    stylePoints &&
    Math.min(
      ...stylePoints.map((stylePoint: SporteditStylePointType) =>
        Number(stylePoint.points),
      ),
    );

  const applicableStylePoints =
    stylePoints &&
    stylePoints.reduce(
      (
        stylePoints: SporteditStylePointsType,
        stylePoint: SporteditStylePointType,
      ) => {
        if (
          Number(stylePoint?.points) === highestJudgePoint &&
          !stylePoints?.find(
            (stylePoint: SporteditStylePointType) => stylePoint.highest,
          )
        ) {
          stylePoint = {
            ...stylePoint,
            highest: true,
            strike: true,
          };
        } else if (
          Number(stylePoint?.points) === lowestJudgePoint &&
          !stylePoints?.find(
            (stylePoint: SporteditStylePointType) => stylePoint?.lowest,
          )
        ) {
          stylePoint = {
            ...stylePoint,
            lowest: true,
            strike: true,
          };
        }
        stylePoints.push(stylePoint);
        return stylePoints;
      },
      [],
    );

  return (
    <S.StylePoints>
      {applicableStylePoints?.map((stylePoint: SporteditStylePointType) => (
        <S.StylePoint key={stylePoint.uuid} $strike={stylePoint?.strike}>
          {stylePoint?.points}
        </S.StylePoint>
      ))}
    </S.StylePoints>
  );
};

export default StylePoints;
