import { NeoScopeResultType } from '@ntb-sport/types';
import { Result } from '../../Result/Result';
import { GroupedJerseys } from '../../Result/Common/Jerseys/Jerseys';
import * as S from '../Results.styled';

export interface GroupedProps {
  scopeResults: NeoScopeResultType[];
  sportUUID: string;
  highlightedCountryUUID?: string;
  allShotSeries: any;
  scopeNumber: number;
  isRelay: boolean;
  labels?: string[];
  disciplineUUID?: string;
  maxRank?: number;
  scopeTypeId: number;
  jerseys: any;
  tourCompetitionUuid?: string;
  isMedalEvent?: boolean;
}

export const Grouped = ({
  scopeResults,
  sportUUID,
  highlightedCountryUUID,
  allShotSeries,
  scopeNumber,
  isRelay,
  labels,
  disciplineUUID,
  jerseys,
  maxRank,
  scopeTypeId,
  tourCompetitionUuid,
  isMedalEvent,
}: GroupedProps) => {
  const groupedScopeResults = scopeResults
    ?.filter((scopeResult) => !scopeResult?.participant?.isJerseyParticipant)
    ?.reduce((acc: any, curr: any, index: number) => {
      if (!acc[curr?.group]) {
        acc[curr?.group] = {
          name: curr?.group,
          order: index,
          scopeResults: [],
        };
      }

      acc[curr?.group].scopeResults.push(curr);
      return acc;
    }, {});

  return (
    <>
      {Object.values(groupedScopeResults)
        .sort((a: any, b: any) => (a.order < b.order ? -1 : 0))
        .map((group: any) => (
          <S.Group key={group.name} as="li">
            <S.GroupHeader>
              {`Gruppe ${group.name}`}
              <GroupedJerseys
                jerseys={jerseys}
                group={group}
                tourCompetitionUuid={tourCompetitionUuid}
              />
            </S.GroupHeader>
            <ul>
              {group.scopeResults
                .filter(
                  (scopeResult: NeoScopeResultType) =>
                    !scopeResult?.participant?.isJerseyParticipant,
                )
                .map((scopeResult: NeoScopeResultType) => (
                  <Result
                    key={scopeResult.uuid}
                    scopeResult={scopeResult}
                    sportUUID={sportUUID}
                    highlightedCountryUUID={highlightedCountryUUID}
                    allShotSeries={allShotSeries}
                    scopeNumber={scopeNumber}
                    isRelay={isRelay}
                    labels={labels}
                    disciplineUUID={disciplineUUID}
                    isMedalEvent={isMedalEvent}
                  />
                ))}
            </ul>
          </S.Group>
        ))}
    </>
  );
};
