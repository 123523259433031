import * as S from './Default.styled';
import { NeoScopeResultType } from '@ntb-sport/types';

import {
  Participant,
  Rank,
  Records,
  Qualified,
  Duration,
  Points,
  DefaultDistance,
} from '../Common';

export enum DefaultTypes {
  DURATION = 'duration',
  DISTANCE = 'distance',
  POINTS = 'points',
}
interface DefaultProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  type: DefaultTypes;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const Default = ({
  scopeResult,
  highlightedCountryUUID,
  type,
  isMedalEvent,
  scopeTypeId,
}: DefaultProps) => {
  return (
    <>
      <S.Left>
        <Rank
          scopeResult={scopeResult}
          highlightedCountryUUID={highlightedCountryUUID}
          isMedalEvent={isMedalEvent}
          scopeTypeId={scopeTypeId}
        />
        <Participant scopeResult={scopeResult} />
      </S.Left>

      <S.Right>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        {type === DefaultTypes.DURATION && (
          <Duration
            duration={scopeResult.duration}
            timeDifference={scopeResult.timeDifference}
            resultId={scopeResult.resultId}
          />
        )}

        {type === DefaultTypes.POINTS && (
          <Points points={scopeResult.points} resultId={scopeResult.resultId} />
        )}

        {type === DefaultTypes.DISTANCE && (
          <DefaultDistance
            distance={scopeResult.distance}
            resultId={scopeResult.resultId}
          />
        )}
      </S.Right>
    </>
  );
};
