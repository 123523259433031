import * as S from './EventStatus.styled';
import { LabelProperties } from 'neo-common-enums';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { MatchesType } from '@ntb-sport/types';
import { WarningIcon } from '@chakra-ui/icons';

import { COMPONENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';

const getEventStatus = ({
  timestamp,
  eventStatusId,
  t,
}: {
  timestamp?: string;
  eventStatusId: number;
  t: any;
}) => {
  const eventTimestamp = timestamp && new Date(timestamp);

  const EVENT_STATUS = {
    [LabelProperties.EventStatus.NOT_STARTED]: eventTimestamp
      ? `${format(eventTimestamp, 'dd.MM.yy')}, ${t('time')} ${format(
          eventTimestamp,
          'HH:mm',
        )} (${t('eventStatus.notStarted')})`
      : `${t('eventStatus.notStarted')}`,
    [LabelProperties.EventStatus.FINISHED]: eventTimestamp
      ? `${format(eventTimestamp, 'dd.MM.yy')}, ${t('time')} ${format(
          eventTimestamp,
          'HH:mm',
        )} (${t('eventStatus.ended')})`
      : `${t('eventStatus.ended')}`,
    [LabelProperties.EventStatus.ONGOING]: (
      <>
        {t('eventStatus.ongoing')}
        <S.Pulse $status={eventStatusId} />
      </>
    ),
    [LabelProperties.EventStatus.ABANDONED]: t('eventStatus.abandoned'),
    [LabelProperties.EventStatus.CANCELLED]: t('eventStatus.cancelled'),
    [LabelProperties.EventStatus.POSTPONED]: t('eventStatus.postponed'),
    [LabelProperties.EventStatus.WILL_NOT_BE_HELD]: t(
      'eventStatus.willNotBeHeld',
    ),
    [LabelProperties.EventStatus.DATE_UNCONFIRMED]: t(
      'eventStatus.dateUnconfirmed',
    ),
    [LabelProperties.EventStatus.INTERRUPTED]: (
      <>
        {t('eventStatus.interrupted')}
        <S.Pulse $status={eventStatusId} />
      </>
    ),
    [LabelProperties.EventStatus.START_DELAYED]: (
      <>
        {t('eventStatus.startDelayed')}
        <S.Pulse $status={eventStatusId} />
      </>
    ),
  };
  return EVENT_STATUS[eventStatusId as keyof typeof EVENT_STATUS] || null;
};

interface EventStatusProps {
  name?: string;
  eventStatusId: number;
  timestamp?: string;
  isLoading?: boolean;
  eventInfo?: string;
  editorialText?: string;
  isCoveredLive?: boolean;
  matches: MatchesType;
  competitionId: string;
}

export const EventStatus = ({
  name,
  eventStatusId,
  timestamp,
  isLoading,
  eventInfo,
  editorialText,
  isCoveredLive,
  matches,
  competitionId,
}: EventStatusProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-component-id={COMPONENT_IDS.EVENT_STATUS}
      data-competition-id={competitionId}
    >
      <S.Wrapper>
        {isLoading ? (
          <S.Container>
            <Skeleton height={50} width={200} />
          </S.Container>
        ) : (
          <>
            <S.Container>
              {name && <S.Name $matches={matches}>{name}</S.Name>}

              <S.Status $isLoading={isLoading} $matches={matches}>
                {getEventStatus({
                  timestamp,
                  eventStatusId,
                  t,
                })}
              </S.Status>
            </S.Container>
            {(eventInfo || editorialText) && (
              <S.InfoWrapper>
                <S.Info>
                  <WarningIcon />
                  {eventInfo && (
                    <S.Comment
                      dangerouslySetInnerHTML={{ __html: eventInfo }}
                    />
                  )}
                  {editorialText && (
                    <S.Comment
                      dangerouslySetInnerHTML={{ __html: editorialText }}
                    />
                  )}
                </S.Info>
              </S.InfoWrapper>
            )}
            {!isCoveredLive &&
              (eventStatusId === LabelProperties.EventStatus.NOT_STARTED ||
                eventStatusId === LabelProperties.EventStatus.ONGOING) && (
                <S.Comment>
                  Resultater tilgjengelig rett i etterkant av konkurransen.
                </S.Comment>
              )}
          </>
        )}
      </S.Wrapper>
    </div>
  );
};
