import * as S from './SkiJumping.styled';
import { NeoScopeResultType } from '@ntb-sport/types';

import {
  Participant,
  Rank,
  Records,
  Qualified,
  Distance,
  Points,
} from '../Common';
import StylePoints from './StylePoints/StylePoints';

interface SkiJumpingProps {
  scopeResult: NeoScopeResultType;
  highlightedCountryUUID?: string;
  isMedalEvent?: boolean;
  scopeTypeId?: number;
}

export const SkiJumping = ({
  scopeResult,
  highlightedCountryUUID,
  isMedalEvent,
  scopeTypeId,
}: SkiJumpingProps) => {
  return (
    <>
      <S.Left>
        <Rank
          scopeResult={scopeResult}
          highlightedCountryUUID={highlightedCountryUUID}
          isMedalEvent={isMedalEvent}
          scopeTypeId={scopeTypeId}
        />
        <Participant scopeResult={scopeResult} />
      </S.Left>

      <S.Right>
        <Qualified resultId={scopeResult?.resultId} />
        <Records records={scopeResult?.records} />

        <Distance
          distance={scopeResult.distance}
          resultId={scopeResult.resultId}
        />
        <Points points={scopeResult.points} resultId={scopeResult.resultId} />

        <StylePoints stylePoints={scopeResult?.stylePoints} />
      </S.Right>
    </>
  );
};
