import { ChevronDownIcon } from '@chakra-ui/icons';

import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import {
  Button,
  Flex,
  MenuChakra as Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '../../chakra';

interface SeasonStandingsMenuProps {
  activeSeasonStandingUrl: any;
  allSeasonStandings: any;
  onClick: any;
  competitionId: string;
}

export const SeasonStandingsMenu = ({
  activeSeasonStandingUrl,
  allSeasonStandings,
  onClick,
  competitionId,
}: SeasonStandingsMenuProps) =>
  activeSeasonStandingUrl &&
  allSeasonStandings?.length > 1 && (
    <Flex
      columnGap={2}
      justifyContent="center"
      data-component-id={COMPONENT_IDS.SEASON_STANDING}
      data-competition-id={competitionId}
      pt={4}
      backgroundColor="var(--background-color)"
    >
      <Menu>
        <MenuButton
          data-element-id={ELEMENT_IDS.BUTTON}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          background="var(--background-color)"
          color="var(--font-color)"
          _hover={{
            backgroundColor: 'var(--background-color__hover)',
            color: 'var(--font-color__hover)',
          }}
        >
          {
            allSeasonStandings?.find(
              (standing: any) =>
                standing?.links?.[0]?.href === activeSeasonStandingUrl,
            )?.name
          }
        </MenuButton>

        <MenuList>
          {allSeasonStandings
            ?.sort((a: any, b: any) => (a.name < b.name ? -1 : 0))
            ?.sort((a: any, b: any) => a?.type === 'overall' && -1)
            ?.map((standing: any) => (
              <MenuItem
                key={standing.uuid}
                onClick={() => onClick(standing?.links?.[0]?.href)}
              >
                {standing?.name}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </Flex>
  );
