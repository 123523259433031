import { CalendarIcon, LocationIcon as LocationIconUI } from '../../icons';

import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

interface StyledType {
  $backgroundImage?: string;
  $backgroundSize?: string;
  $backgroundPosition?: string;
}

export const EventHero = styled.div<StyledType>`
  container-type: inline-size;
  container-name: container;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${(props) => `url(${props?.$backgroundImage})`};
  background-size: ${(props) => props?.$backgroundSize || 'cover'};
  background-position: ${(props) => props?.$backgroundPosition || 'center'};
  background-repeat: no-repeat;
  background-color: var(--background-color);
  color: var(--font-color);
  padding: var(--ntb-space-6);

  &:after {
    content: '';
    background-color: var(--background-color-overlay);
    transition: background 300ms ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @container container ${MEDIA_QUERY.MOBILE_L} {
    padding: var(--ntb-space-8);
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--ntb-space-2);
  z-index: 1;
`;

export const Competition = styled.div`
  display: flex;
  align-items: center;
`;

export const CompetitionName = styled.div<StyledType>`
  text-transform: uppercase;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-normal);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Name = styled.div<StyledType>`
  font-size: var(--ntb-fontSizes-lg);
  font-weight: var(--ntb-fontWeights-medium);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-xl);
  }
`;

export const DateAndLocation = styled.div<StyledType>`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
`;

export const EventDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
`;

export const EventDateIcon = styled(CalendarIcon)`
  fill: none !important; //Override nrk css
`;

export const EventDateName = styled.span<StyledType>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
`;

export const LocationIcon = styled(LocationIconUI)`
  fill: none !important; //Override nrk css
`;

export const LocationCountry = styled.span<StyledType>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const LocationName = styled.span<StyledType>`
  white-space: pre-wrap;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const TvChannels = styled.div<StyledType>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: var(--ntb-space-1);
`;

export const TvChannel = styled.span<StyledType>`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-light);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;

export const BroadcastChannels = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: broadcast;
  column-gap: var(--ntb-space-2);
  margin-top: var(--ntb-space-4);
`;

export const Comments = styled.div`
  margin-top: var(--ntb-space-2);
`;

export const Comment = styled.div<StyledType>`
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);

  @container container ${MEDIA_QUERY.MOBILE_L} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
