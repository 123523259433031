import ResultId from '../ResultId/ResultId';
import * as S from './Duration.styled';

interface DurationProps {
  duration: string | undefined;
  timeDifference: string | undefined;
  resultId: number;
}

export const Duration = ({
  duration,
  timeDifference,
  resultId,
}: DurationProps) => {
  return (
    <S.Duration>
      <ResultId resultId={resultId} />
      {duration && <S.Primary>{duration}</S.Primary>}
      {timeDifference && <S.Secondary>{timeDifference}</S.Secondary>}
    </S.Duration>
  );
};
