import { SPORT_ID } from '@ntb-sport/constants';

export const getNorkonCustomer = ({ uuid }: { uuid: string }) => {
  const customers = {
    '54d017c7-e541-4ac2-a6f3-f53db92d7a74': {
      tenantKey: 'nrk',
      channelIds: {
        [SPORT_ID.BIATHLON]: { tenantKey: 'nrk', id: 40106 },
        [SPORT_ID.CROSS_COUNTRY_SKIING]: { tenantKey: 'nrk', id: 40105 },
        [SPORT_ID.ALPINE]: { tenantKey: 'nrk', id: 39834 },
        [SPORT_ID.SKI_JUMPING]: { tenantKey: 'nrk', id: 39835 },
        [SPORT_ID.NORDIC_COMBINED]: { tenantKey: 'nrk', id: 40802 },
        [SPORT_ID.ATHLETICS]: { tenantKey: 'nrk', id: 44069 },
        [SPORT_ID.CYCLING]: { tenantKey: 'nrk', id: 49238 },
      },
    },
  };

  return customers[uuid as keyof typeof customers];
};
