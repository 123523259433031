import styled from 'styled-components';

interface StyledProps {
  $active?: boolean;
  itemId?: string;
}

export const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--ntb-space-3) var(--ntb-space-2);
  background-color: var(--background-color);
  color: var(--font-color);

  .react-horizontal-scrolling-menu--wrapper {
    display: grid;
    grid-template-columns: 36px 1fr 36px;
    grid-column-gap: var(--ntb-space-2);
    align-items: center;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    column-gap: var(--ntb-space-2);
    overflow: hidden;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const Wrapper = styled.div`
  max-width: 100%;
`;

export const MenuItem = styled.button.withConfig({
  shouldForwardProp: (prop) => !['itemId'].includes(prop),
})<StyledProps>`
  white-space: nowrap;
  height: 24px;
  background-color: ${(props) =>
    props.$active
      ? 'var(--background-color__active)'
      : 'var(--background-color)'};
  color: ${(props) =>
    props.$active ? 'var(--font-color__active)' : 'var(--font-color)'};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.$active ? 'var(--border-color__active)' : 'var(--border-color)'};
  padding: 0 var(--ntb-space-6);
  border-radius: var(--ntb-radii-md);
  cursor: pointer;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-semibold);

  &:hover {
    background-color: var(--background-color__hover);
    color: var(--font-color__hover);
    border-color: var(--border-color__hover);
  }

  &::first-letter {
    text-transform: capitalize;
  }
`;
