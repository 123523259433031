import styled from 'styled-components';

import { MEDIA_QUERY } from '@ntb-sport/constants';

interface ResultIdType {
  $highlightType?: string;
}

export const ResultId = styled.div<ResultIdType>`
  grid-area: result;
  color: ${(props) =>
    props.$highlightType === 'success' && 'var(--base-colors-success)'};
  color: ${(props) =>
    props.$highlightType === 'error' && 'var(--base-colors-error)'};

  font-size: var(--ntb-fontSizes-xs);

  @container scopeResults ${MEDIA_QUERY.TABLET} {
    font-size: var(--ntb-fontSizes-sm);
  }
`;
