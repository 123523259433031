import { SporteditRecordsType } from '@ntb-sport/types';
import * as S from './Records.styled';
import { ELEMENT_IDS } from '@ntb-sport/constants';

interface RecordsProps {
  records: any;
}

interface RecordProps {
  record: SporteditRecordsType;
}

const Record = ({ record }: RecordProps) => {
  return (
    <S.Record data-element-id={ELEMENT_IDS.TYPE_SUCCESS}>
      {`${record?.equalsCurrentRecord ? '=' : ''}${
        record?.recordType?.abbreviation
      }`}
    </S.Record>
  );
};

export const Records = ({ records }: RecordsProps) => {
  const displayRecords = records?.length > 0;

  if (!displayRecords) return null;

  return (
    <S.Records>
      {records?.map((record: any) => (
        <Record key={record?.recordType?.uuid} record={record} />
      ))}
    </S.Records>
  );
};

export default Records;
