import * as S from './EventMenu.styled';
import { LeftArrow, RightArrow } from './Arrows';
import { ScrollMenu as ReactHorizontalScrollMenu } from 'react-horizontal-scrolling-menu';

import { useDrag } from '@ntb-sport/hooks';
import { COMPONENT_IDS, ELEMENT_IDS } from '@ntb-sport/constants';
import { Skeleton } from '../../chakra';

export const VIEW = {
  RESULTS: 'results',
  TOUR: 'tour',
  BLOG: 'blog',
  QUALIFIER: 'qualifier',
  KNOCKOUT_ROUNDS: 'knockout',
  MEDAL_TABLE: 'medal-table',
  STANDINGS: 'standings',
  OVERALL_STANDINGS: 'overall-standings',
};

interface EventMenuType {
  isLoading?: boolean;
  onClick: ({
    view,
    uuid,
    group,
    roundTypeUuid,
    disciplineUuid,
  }: {
    view: string;
    uuid: string;
    group: string;
    roundTypeUuid: string;
    disciplineUuid: string;
  }) => void;
  menuItems: any;
  activeItemUuid?: any;
  competitionId?: string;
}

export const EventMenu = ({
  isLoading,
  onClick,
  menuItems,
  activeItemUuid,
  competitionId,
}: EventMenuType) => {
  const { dragStart, dragStop, dragMove, dragging, touchStart, touchMove } =
    useDrag();

  const handleDrag =
    ({ scrollContainer }: { scrollContainer: any }) =>
    (ev: any) =>
      dragMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleTouchDrag =
    ({ scrollContainer }: { scrollContainer: any }) =>
    (ev: any) =>
      touchMove(ev, (posDiff: any) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleItemClick = ({
    view,
    uuid,
    group,
    roundTypeUuid,
    disciplineUuid,
  }: {
    view: any;
    uuid: string;
    group: string;
    roundTypeUuid: string;
    disciplineUuid: string;
  }) => {
    if (dragging) {
      return false;
    }
    onClick({ view, uuid, group, roundTypeUuid, disciplineUuid });
    return;
  };

  const centerOnInit = ({
    getItemById,
    scrollToItem,
  }: {
    getItemById: any;
    scrollToItem: any;
  }) => {
    scrollToItem(getItemById(activeItemUuid), 'auto', 'center');
  };

  return (
    <S.Container
      data-component-id={COMPONENT_IDS.SCROLL_MENU}
      data-competition-id={competitionId}
    >
      <S.Wrapper>
        {isLoading ? (
          <Skeleton height={30} width={300} />
        ) : (
          <ReactHorizontalScrollMenu
            LeftArrow={<LeftArrow />}
            RightArrow={<RightArrow />}
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            onTouchStart={() => touchStart}
            onTouchEnd={() => dragStop}
            onTouchMove={handleTouchDrag}
            onInit={centerOnInit}
          >
            {menuItems?.map((menuItem: any) => {
              return (
                <S.MenuItem
                  data-element-id={ELEMENT_IDS.BUTTON}
                  key={menuItem?.uuid}
                  itemId={menuItem?.roundTypeUuid || menuItem?.uuid}
                  $active={
                    menuItem?.uuid === activeItemUuid ||
                    menuItem?.roundTypeUuid === activeItemUuid ||
                    menuItem?.disciplineUuid === activeItemUuid
                  }
                  onClick={() =>
                    handleItemClick({
                      view: menuItem.view,
                      uuid: menuItem?.uuid,
                      group: menuItem?.group,
                      roundTypeUuid: menuItem?.roundTypeUuid,
                      disciplineUuid: menuItem?.disciplineUuid,
                    })
                  }
                >
                  {menuItem?.name}
                </S.MenuItem>
              );
            })}
          </ReactHorizontalScrollMenu>
        )}
      </S.Wrapper>
    </S.Container>
  );
};
