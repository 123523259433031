import React, { Suspense, useContext } from 'react';
import { NifsCustomerContext } from '@ntb-sport/customer';
import {
  CUSTOMERS,
  CUSTOMER_CONCERN_IDS,
} from 'libs/customer/src/lib/Customers';

const NrkTheme = React.lazy(() => import('./NrkTheme'));
const MinimalTheme = React.lazy(() => import('./MinimalTheme'));

export const THEMES = {
  [CUSTOMER_CONCERN_IDS.NRK]: <NrkTheme />,
  [CUSTOMER_CONCERN_IDS.NETTAVISEN]: <MinimalTheme />,
  [CUSTOMER_CONCERN_IDS.AMEDIA]: <MinimalTheme />,
  [CUSTOMER_CONCERN_IDS.AMEDIA_PARTNER]: <MinimalTheme />,
};

export const ThemeProvider = ({ children }) => {
  const { nifsCustomer } = useContext(NifsCustomerContext);

  const metaData = CUSTOMERS?.find(
    (customer) => customer?.id === nifsCustomer?.customer?.id,
  );

  return (
    <div data-style-id={metaData?.styleId}>
      <Suspense fallback={<></>}>
        {THEMES?.[nifsCustomer?.customer?.id] ||
          THEMES?.[nifsCustomer?.customer?.customerConcernId] || (
            <MinimalTheme />
          )}
      </Suspense>
      {children}
    </div>
  );
};

export default ThemeProvider;
