import ResultId from '../ResultId/ResultId';
import * as S from './Points.styled';

interface PointsProps {
  points: number;
  resultId: number;
}

export const Points = ({ points, resultId }: PointsProps) => {
  return (
    <S.Points>
      <ResultId resultId={resultId} />
      {points && `${points}p`}
    </S.Points>
  );
};
