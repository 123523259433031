import { NeoEventType } from '@ntb-sport/types';
import { EventType, SportType } from '../types';
import { MainEvent } from './MainEvent';
import * as S from './MainEvents.styled';

interface MainEventsProps {
  events: EventType[];
  date: string;
  sport: SportType;
  onClick?: ({
    sportGroup,
    sportIdTranslated,
    sportId,
    date,
    uuid,
  }: {
    sportGroup: string;
    sportIdTranslated: string;
    sportId: string;
    date: string | false;
    uuid: string;
  }) => Promise<boolean> | void;
}

export const MainEvents = ({
  events,
  date,
  sport,
  onClick,
}: MainEventsProps) => {
  return (
    <S.Events>
      {Object?.values(events)
        ?.sort((a: EventType, b: EventType) =>
          new Date(a?.timestamp) < new Date(b?.timestamp) ? -1 : 0,
        )
        ?.map((event: EventType) => (
          <MainEvent
            key={event?.uuid}
            event={event}
            date={date}
            sport={sport}
            onClick={onClick}
          />
        ))}
    </S.Events>
  );
};
