import styled, { css } from 'styled-components';

import { ArrowIcon } from '../../icons';
import { MatchesType } from '@ntb-sport/types';

interface StyledProps {
  $matches?: MatchesType;
  $isOpen?: boolean;
  $hasTertiaryColumn?: boolean;
  $enableToggle?: boolean;
  $highlight?: boolean;
}

export const Container = styled.div`
  container-type: inline-size;
  container-name: container;

  background-color: var(--background-color);
`;

export const NoResults = styled.div`
  padding: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-sm);
  text-align: center;
`;

export const EventParticipantsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ntb-space-2);
  background-color: var(--header-background-color);
  color: var(--header-font-color);
`;

export const AbbreviationInfoContainer = styled.div`
  margin-left: auto;
`;

export const StyledEventParticipants = styled.ul`
  margin: 0;
  padding: var(--ntb-space-2);
  list-style-type: none;
`;

export const EventParticipant = styled.li<StyledProps>`
  display: grid;
  grid-template-areas: 'bibNumber participant  primary';
  grid-template-columns: max-content auto max-content;
  grid-column-gap: 6px;
  align-items: center;
  padding: ${(props) =>
    props.$isOpen ? 'var(--ntb-space-1) 0 0 0' : 'var(--ntb-space-1) 0'};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--border-color);

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      grid-column-gap: var(--ntb-space-2);
      grid-template-columns: max-content auto max-content;
    `}
`;

export const Rank = styled.div<StyledProps>`
  grid-area: bibNumber;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-size: var(--ntb-fontSizes-xs);
  background-color: var(--background-color);
  font-weight: ${(props) =>
    props.$highlight && 'var(--ntb-fontWeights-semibold)'};
  color: var(--font-color);
  border-radius: var(--ntb-radii-md);

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      width: 25px;
      height: 25px;
    `}
`;

export const Participant = styled.div`
  grid-area: participant;
  display: flex;
  align-items: center;
  color: var(--base-colors-blackAlpha-900);
`;

export const Primary = styled.div<StyledProps>`
  grid-area: primary;
  justify-self: end;
  display: flex;
  font-size: ${(props) =>
    props?.$matches?.tablet
      ? 'var(--ntb-fontSizes-sm)'
      : 'var(--ntb-fontSizes-xs)'};
  font-weight: var(--ntb-fontWeights-semibold);
  color: var(--base-colors-blackAlpha-900);
`;

export const Toggle = styled(ArrowIcon)`
  grid-area: toggle;
  display: flex;
  align-items: center;
  justify-self: center;
  color: ${(props) => props?.theme?.fontColor};
  width: 20px;
  height: 20px;
  transform: ${(props) => props.$isOpen && 'rotate(180deg)'};
`;

export const ExpandedParticipants = styled.ul<StyledProps>`
  grid-area: expandedParticipants;
  margin: 5px 0px 0px;
  padding: 10px 40px 10px 70px;
  list-style-type: none;
  background: rgb(246, 246, 246);
  box-shadow: rgb(0 0 0 / 5%) 0px -1px 0px inset;

  ${(props) =>
    props?.$matches?.mobileL &&
    css`
      padding: 10px 40px 10px 80px;
    `}
`;

export const ExpandedParticipant = styled.li`
  display: grid;
  grid-template-areas: 'number name';
  grid-template-columns: 20px auto;
  padding: 10px 0px;
  margin: 0;
  box-shadow: rgb(0 0 0 / 5%) 0px -1px 0px inset;
`;

export const ExpandedParticipantNumber = styled.div`
  grid-area: number;
`;

export const ExpandedParticipantName = styled.div`
  grid-area: name;
  display: flex;
`;

export const ExpandedParticipantPrimaryName = styled.div`
  margin-right: 5px;
`;

export const ExpandedParticipantSecondaryName = styled.div`
  color: #26292a;
`;

export const Pair = styled.div`
  margin: 24px 0 12px 0;
`;

export const PairName = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  margin-left: 12px;
`;
