import styled from 'styled-components';

export const ContainerQuery = styled.div`
  container-type: inline-size;
  container-name: scopeResults;
`;

export const ScopeResultsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--ntb-space-2);
  background-color: var(--background-color);
  color: var(--font-color);
`;

export const SectionTitle = styled.h2`
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-semibold);
`;

export const AbbreviationInfoContainer = styled.div`
  margin-left: auto;
`;

export const NoResults = styled.div`
  padding: var(--ntb-space-2);
  text-align: center;
  font-size: var(--ntb-fontSizes-sm);
`;

export const ScopeResults = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--background-color);
  padding: var(--ntb-space-2);
`;

export const MaxRank = styled.li`
  padding: var(--ntb-space-2);
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--result-border-color);
`;
