import { COMPONENT_IDS } from '@ntb-sport/constants';
import { SportEditCustomerContext } from '@ntb-sport/customer';
import { getNorkonCustomer } from '@ntb-sport/helpers';
import { format } from 'date-fns';
import { useContext, useEffect, useRef } from 'react';
import { nb } from 'date-fns/locale';
import { NeoEventType } from '@ntb-sport/types';
import * as S from './NorkonBlog.styled';

interface NorkonBlogProps {
  sportId: string;
  event: NeoEventType;
}

export const NorkonBlog = ({ sportId, event }: NorkonBlogProps) => {
  const { customer } = useContext<any>(SportEditCustomerContext);

  const blogRef = useRef<any>();
  const loadMoreRef = useRef<any>();

  const norkonCustomer = getNorkonCustomer({
    uuid: customer?.uuid,
  });

  useEffect(() => {
    const channel = norkonCustomer?.channelIds[sportId];

    if ((window as any).NcPosts && channel) {
      (window as any).NcPosts.start({
        channelId: channel.id,
        tenantKey: channel?.tenantKey,
        container: blogRef.current,
        showMoreElement: loadMoreRef.current,
        rendererOptions: {
          headerRenderer: {
            dateFormatter: (d: Date) =>
              format(new Date(d), 'd. MMMM yyyy HH:mm', {
                locale: nb,
              }),
          },
        },
      });
    }
  }, [event, (window as any).NcPosts, blogRef.current]);

  return (
    <>
      <S.Container
        data-component-id={COMPONENT_IDS.LIVE_FEED}
        data-competition-id={event?.seasons?.[0]?.competition?.uuid}
      >
        <div className="ntb">
          <div className="lc-feed-container">
            <div id="master-container" ref={blogRef}></div>
            <div style={{ textAlign: 'center' }}>
              <button
                className="lc-load-more"
                id="lc-load-more"
                ref={loadMoreRef}
              >
                Se flere
              </button>
            </div>
          </div>
        </div>
      </S.Container>
    </>
  );
};
