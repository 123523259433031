import styled from 'styled-components';

export const Container = styled.div`
  min-width: 60px;
`;

export const InfoList = styled.dl`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-column-gap: var(--ntb-space-2);
  grid-row-gap: var(--ntb-space-2);
`;

export const InfoListDT = styled.dt`
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);
  color: var(--font-color);
`;

export const InfoListDD = styled.dd`
  font-size: var(--ntb-fontSizes-xs);
`;
