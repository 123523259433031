import * as S from './SubEventGroup.styled';

import { getEventStatus } from '../helpers/getEventStatus';
import { LabelProperties } from 'neo-common-enums';
import { NeoBroadcastType } from '@ntb-sport/types';
import { EventType } from '../types';
import { FaMedal } from 'react-icons/fa6';

interface SubEventGroupProps {
  eventGroup: EventType;
}

export const SubEventGroup = ({ eventGroup }: SubEventGroupProps) => {
  const eventStatus = getEventStatus({
    eventStatusId: eventGroup?.eventStatusId,
    timestamp: eventGroup?.timestamp,
  });

  return (
    <S.SubEventGroup>
      <S.SubEventGroupStatus $type={eventStatus?.type}>
        {eventStatus?.type === 'default' && eventStatus?.text}
      </S.SubEventGroupStatus>
      <S.SubEventGroupContainer>
        <S.ChildEventName>{eventGroup?.name}</S.ChildEventName>
        {eventStatus?.type !== 'default' && (
          <>
            {' - '}
            <S.StatusInfo $type={eventStatus?.type}>
              {eventStatus?.text}
            </S.StatusInfo>
          </>
        )}
        {eventGroup?.hasAthletesFromCountry && (
          <S.ChildEventHasAthletesFromCountry countryCode="no" />
        )}
        {eventGroup?.isMedalEvent && <FaMedal />}
      </S.SubEventGroupContainer>

      <S.Broadcast>
        {eventGroup?.broadcasts
          ?.filter(
            (broadcast: NeoBroadcastType) =>
              broadcast?.typeId === LabelProperties.BroadcastType.TV,
          )
          ?.map((broadcast: NeoBroadcastType) => (
            <S.BroadcastChannel key={broadcast?.uuid}>
              {broadcast?.broadcastingChannel?.name}
            </S.BroadcastChannel>
          ))}
      </S.Broadcast>
    </S.SubEventGroup>
  );
};
