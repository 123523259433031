import styled from 'styled-components';

export const Results = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--background-color);
  padding: var(--ntb-space-2);
`;

export const Group = styled.div`
  margin-bottom: var(--ntb-space-4);
`;
export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--ntb-space-2);
  font-size: var(--ntb-fontSizes-md);
  font-weight: var(--ntb-fontWeights-medium);
`;

export const Jerseys = styled.div`
  font-size: var(--ntb-fontSizes-sm);
  font-weight: var(--ntb-fontWeights-normal);
`;

export const MaxRank = styled.li`
  padding: var(--ntb-space-2);
  text-align: center;
  font-size: var(--ntb-fontSizes-xs);
  font-weight: var(--ntb-fontWeights-medium);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--result-border-color);
`;
